import { Link } from "react-router-dom";

const ColorCanvasSidebar = ({
  category,
  title,
  image,
  isPreviewVisible,
  setPreviewVisibility,
}) => {
  return (
    <>
      {isPreviewVisible && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-blur-lg" />
      )}
      <div
        className={`bg-secondary sm:block sm:h-full px-9 sm:px-4 lg:px-9 sm:py-14 lg:w-[20vw] sm:w-[17vw] w-[80vw] rounded-xl text-center 
          ${
            isPreviewVisible
              ? "z-50 absolute right-8 top-8 h-[100vw]"
              : "hidden"
          }`}
      >
        <div className="flex justify-center">
          <Link to={`/draw/${category}/${title}`}>
            <button className="bg-btn2 text-secondary sm:block hidden px-3 py-2 rounded-lg hover:opacity-80 mb-5 lg:text-xl text-md">
              Open Drawing Tutorial
            </button>
          </Link>
        </div>
        <div className="sm:mt-10 mt-8 sm:py-auto py-4">
          <h1 className="text-xl text-textColor font-bold">DRAWING PREVIEW</h1>
          <button
            className={`sm:hidden bg-white absolute right-4 top-4 text-sm border rounded-full px-1
            ${isPreviewVisible ? "block" : "hidden"}`}
            onClick={() => setPreviewVisibility(false)}
          >
            X
          </button>
          <img
            src={image}
            alt="preview"
            className="sm:mt-7 mt-4 border-[1px] border-textColor p-3 rounded-xl"
          />
        </div>
      </div>
    </>
  );
};

export default ColorCanvasSidebar;
