import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PremiumAd from "../components/PremiumAd";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import seodata from "../utils/seodata.json";
import { goBack } from "../utils/backFunction";
import { ReactComponent as InstagramIcon } from "../assets/instagram.svg";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import backIcon from "../assets/backbutton.png";

const Contact = () => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>{seodata.contactuspage.seotitle}</title>
        <meta
          name="description"
          content={seodata.contactuspage.seometadescription}
        />
        <meta
          name="keywords"
          content={seodata.contactuspage.seokeywords.join(", ")}
        />
        <h1>{seodata.contactuspage.seoh1}</h1>
        <h2>{seodata.contactuspage.seoh2.join(", ")}</h2>
        <body>{seodata.contactuspage.seobody}</body>
      </Helmet>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              💌 Know Us Better, Ad-Free! Premium offers a distraction-free
              experience on our key pages.
            </span>
            <span className="sm:hidden">💌 Know Us Better, Ad-Free!</span>
          </>
        }
        cta="Join Premium"
      />
      <Header />
      <div
        className="flex justify-center sm:mt-12 mt-10 sm:mb-20 mb-9 sm:px-20 px-5"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute lg:left-14 sm:left-6 left-5 lg:top-auto top-[-10px]"
        >
          <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
        </button>
        <div className="w-full lg:max-w-4xl sm:text-3xl max-w-2xl bg-secondary rounded-lg sm:p-10 p-5">
          <div className="text-center">
            <h2 className="lg:text-4xl sm:text-3xl text-lg font-bold text-textColor">
              Get in Touch with Us!
            </h2>
            <p className="sm:text-lg text-sm lg:mt-4 mt-2 text-textColor">
              We love hearing from our creative community. Reach out to us for
              any queries or collaboration ideas.
            </p>
          </div>

          <div className="sm:mt-10 mt-4">
            <h3 className="sm:text-2xl text-md font-bold text-textColor">
              Contact Information
            </h3>
            <div className="flex flex-col sm:mt-4 mt-2 sm:space-y-3 space-y-1">
              <p className="sm:text-lg text-sm text-textColor">
                General, Billing, Payments, Refunds:{" "}
                <a
                  href="mailto:hello@drawinkpaper.com"
                  className="text-primary underline"
                >
                  hello@drawinkpaper.com
                </a>
              </p>
              <p className="sm:text-lg text-sm text-textColor">
                Technical Support, Report Issues:{" "}
                <a
                  href="mailto:support@drawinkpaper.com"
                  className="text-primary underline"
                >
                  support@drawinkpaper.com
                </a>
              </p>
            </div>
          </div>

          <div className="sm:mt-10 mt-4">
            <h3 className="sm:text-2xl text-md font-bold text-textColor">
              Follow Us on Social Media
            </h3>
            <div className="flex mt-4 sm:space-x-6 space-x-2 justify-center">
              <a
                href="https://www.instagram.com/drawinknpaper/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="w-8 h-8" />
              </a>
              <a
                href="https://twitter.com/drawinknpaper"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="w-8 h-8" />
              </a>
              <a
                href="https://twitter.com/drawinknpaper"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon className="w-8 h-8" />
              </a>
            </div>
          </div>

          <div className="sm:mt-10 mt-4">
            <h3 className="sm:text-2xl text-md font-bold text-textColor">
              Our Office
            </h3>
            <p className="sm:text-lg text-sm sm:mt-4 mt-2 text-textColor">
              DrawInkPaper
              <br />
              Alai Technologies LLC
              <br />
              16192 Coastal Highway <br />
              Lewes, Delaware, County of Susex,
              <br />
              USA, 19958
            </p>
          </div>

          <div className="text-center sm:mt-10 mt-4">
            <h4 className="sm:text-xl text-md font-bold text-textColor">
              Join our creative journey at DrawInkPaper.com!
            </h4>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
