import { Link } from "react-router-dom";
import CheckPremiumStatus from "./CheckPremiumStatus";
import adbg from "../assets/breakinadbg.png";

const BreakinAd = () => {
  return (
    <CheckPremiumStatus>
      {(isPremiumUser) => (
        <div>
          {!isPremiumUser && (
            <div
              className="bg-[#FACC15] sm:p-4 p-2 rounded-lg shadow-md text-center m-1 bg-cover"
              style={{ backgroundImage: `url(${adbg})` }}
            >
              <div className="md:col-span-2 lg:col-span-2">
                <p className="hidden lg:block text-xl font-bold text-md mb-4">
                  Click the link to upgrade to the most premium experience with
                  no ad breaks, free ebooks and endless art!
                </p>
                <p className="text-md sm:text-lg font-bold mb-1 lg:hidden">
                  Click the link to upgrade and access free e-books and coloring
                  downloads.
                </p>
              </div>
              <div className="flex items-center justify-center">
                <Link to="/checkout">
                  <button className="bg-[#3B82F6] text-[#fff] lg:text-lg md:text-md text-sm py-1 px-2 rounded hover:bg-[#2563F6]">
                    Get Started
                  </button>
                </Link>
              </div>
            </div>
          )}
        </div>
      )}
    </CheckPremiumStatus>
  );
};

export default BreakinAd;
