import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Home from "./Home";

const Draw = () => {
  const url = window.location.href;
  const urlParts = url.split("/");
  const drawWord = urlParts[urlParts.length - 1];

  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return <Home word={drawWord} />;
};

export default Draw;
