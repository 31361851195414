import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const Privacy = () => {
  // Get the current date
  const currentDate = new Date();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [isContentVisible, setContentVisibility] = useState(false);

  //Animate on Scroll
  useEffect(() => {
    const initAOS = () => {
      AOS.init();
      AOS.refresh();
    };

    // Initialize AOS after the sidebar is visible
    if (isContentVisible) {
      initAOS();
    }

    // Listen for changes in isSidebarVisible and reinitialize AOS
    const onContentVisibilityChange = () => {
      if (isContentVisible) {
        initAOS();
      }
    };

    // Add event listener for changes in isSidebarVisible
    window.addEventListener("resize", onContentVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", onContentVisibilityChange);
    };
  }, [isContentVisible]);

  // Define the month names
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Format the date in the desired format
  const formattedDate = `${currentDate.getDate()} ${
    months[currentDate.getMonth()]
  }, ${currentDate.getFullYear()}`;

  return (
    <>
      <Header />
      <div
        className="flex justify-between lg:space-x-14 mt-12 lg:mb-20 mb-9 lg:px-20 px-5"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute sm:left-14 left-3 lg:top-auto sm:top-4 top-[-0.5rem]"
        >
          <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
        </button>

        <div className="lg:w-[75vw] w-[90vw] bg-secondary rounded-lg lg:px-5 px-2 lg:pt-11 pt-5 lg:pb-16 pb-8 mx-auto">
          <div className="text-center">
            <h2 className="sm:text-2xl text-lg sm:pt-0 pt-3 font-bold text-textColor">
              Privacy Policy for DrawInkPaper
            </h2>
            <p className="sm:text-lg text-base sm:mt-2 mt-1 text-textColor font-bold">
              Last Updated: {formattedDate}
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="introduction">
            <h1 className="sm:text-2xl text-md">1) Introduction</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              Welcome to DrawInkPaper, the go-to digital platform designed to
              help kids unleash their creativity through drawing. We're
              committed to protecting the privacy and security of our users'
              information. This Privacy Policy is designed to help you
              understand what information we collect, how we use it, and what
              choices you have.
              <br />
              <span className="font-bold">
                By accessing or using our platform, you consent to the
                collection, use, and sharing of your information as outlined in
                this Privacy Policy.
              </span>
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="collect">
            <h1 className="sm:text-2xl text-md">2) Information We Collect</h1>
            <div className="mt-3 text-textColor">
              <h5 className="font-bold sm:text-xl text-md">
                Personal Information:
              </h5>
              <div className="sm:mt-3 mt-1 sm:text-lg text-sm">
                <div className="sm:mt-2 mt-1">
                  <p>
                    Name: We collect the names of our users to personalize their
                    experience on our platform.
                  </p>
                </div>
                <div className="sm:mt-2 mt-1">
                  <p>
                    Email ID: We use your email ID to send you updates,
                    newsletters, and other relevant communications.
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-3 text-textColor">
              <h5 className="font-bold sm:text-xl text-md">
                Non Personal Information:
              </h5>
              <div className="sm:mt-3 mt-1 sm:text-lg text-sm">
                <div className="sm:mt-2 mt-1">
                  <p>
                    Usage Data: We collect information about how our services
                    are accessed and used.
                  </p>
                </div>
                <div className="sm:mt-2 mt-1">
                  <p>
                    Cookies: Our platform uses cookies to improve user
                    experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="usage">
            <h1 className="sm:text-2xl text-md">
              3) How We Use Your Information
            </h1>
            <div className="sm:mt-5 mt-2 text-textColor">
              <p className="font-bold sm:text-xl text-md">
                Personalize your Experience:
              </p>
              <div className="sm:mt-2 mt-1 text-sm">
                <p>
                  Knowing your name helps us tailor your experience on
                  DrawInkPaper.
                </p>
              </div>
            </div>
            <div className="mt-2 text-textColor">
              <p className="font-bold text-md">Communication:</p>
              <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                <p>
                  We use your email ID to send you newsletters, updates, and to
                  respond to your queries.
                </p>
              </div>
            </div>
            <div className="mt-2 text-textColor">
              <p className="font-bold text-md">Service Improvement:</p>
              <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                <p>
                  We analyze usage data to improve our platform and to
                  understand user behavior.
                </p>
              </div>
            </div>
            <div className="mt-2 text-textColor">
              <p className="font-bold sm:text-xl text-md">Legal Compliance: </p>
              <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                <p>To comply with applicable laws and regulations.</p>
              </div>
            </div>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="sharing">
            <h1 className="sm:text-2xl text-md">4) Sharing Your Information</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              We do not sell, trade, or rent users’ personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners and
              trusted affiliates for the purposes outlined above.
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="security">
            <h1 className="sm:text-2xl text-md">5) Security</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              We take security seriously and have put in place appropriate
              physical, electronic, and managerial procedures to safeguard the
              information we collect.
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="children">
            <h1 className="sm:text-2xl text-md">6) Children's Privacy</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              DrawInkPaper is designed for children, and as such, we comply with
              applicable laws and regulations concerning children's privacy,
              including the Children's Online Privacy Protection Act (COPPA).
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="choices">
            <h1 className="sm:text-2xl text-md">7) Your Choices</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              You may opt-out of any future contacts from us at any time. You
              can do the following by contacting us via the email address
              provided on our website:
              <div className="sm:mt-2 mt-1 sm:px-auto px-1">
                <li>See what data we have about you, if any.</li>
                <li>Change or correct any data we have about you.</li>
                <li>Have us delete any data we have about you.</li>
              </div>
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="updates">
            <h1 className="sm:text-2xl text-md">8) Updates to This Policy</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              We reserve the right to update or change our Privacy Policy at any
              time. Any changes will be posted on this page, and if the changes
              are significant, we will provide a more prominent notice, which
              may include email notification.
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="contact">
            <h1 className="sm:text-2xl text-md">9) Contact Us</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              For any questions or concerns regarding this Privacy Policy,
              please contact us at: <br />
              <span className="font-bold">Email: hello@drawinkpaper.com </span>
              <br />
              <span className="font-bold sm:text-xl text-sm">
                Thank you for choosing DrawInkPaper!
              </span>
            </p>
          </div>
        </div>

        <div
          className={`lg:w-[25vw] sm:w-[30vw] sm:block w-[80vw] bg-secondary rounded-lg cursor-pointer h-fit sm:left-auto left-10
        ${isContentVisible ? "block z-20 absolute" : "hidden"}`}
          data-aos="fade-up"
        >
          <h1 className="lg:text-2xl text-xl text-center sm:mt-7 mt-4 font-bold text-textColor">
            Table of Contents
          </h1>
          <button
            className="sm:hidden bg-white absolute right-4 top-4 text-sm border rounded-full px-1"
            onClick={() => setContentVisibility(false)}
          >
            X
          </button>
          <div className="sm:mt-5 mt-2 sm:mb-5 mb-2 text-center">
            <a href="#introduction">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Introduction</p>
              </div>
            </a>
            <a href="#collect">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Information We Collect</p>
              </div>
            </a>
            <a href="#usage">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">
                  How We Use Your Information
                </p>
              </div>
            </a>
            <a href="#sharing">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Sharing Your Information</p>
              </div>
            </a>
            <a href="#security">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Security</p>
              </div>
            </a>
            <a href="#children">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Children's Privacy</p>
              </div>
            </a>
            <a href="#choices">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Your Choices</p>
              </div>
            </a>
            <a href="#updates">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Updates To This Policy</p>
              </div>
            </a>
            <a href="#contact">
              <div className="hover:bg-tertiary sm:py-3 py-1">
                <p className="lg:text-lg text-md">Contact Us</p>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
