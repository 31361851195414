import { useState, useEffect } from "react";
import { account, avatars } from "../appwrite/appwriteConfig";

const useUserData = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await account.get();
        const user_data = {
          name: data.name,
          id: data.$id,
          email: data.email,
          avatar: avatars.getInitials(data.name, 100, 100, "2EB6EA").href,
        };
        setUserData(user_data);
      } catch (error) { }
    };
    getUserData();
  }, []);
  return userData;
};

export default useUserData;
