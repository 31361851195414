import { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const CheckoutForm = () => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Function to handle stripe checkout
  const stripePricingTableId = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
  const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

  return (
    <div>
      <Header />
      <div className="bg-[#53d5fd]" data-aos="fade-up">
        <div className="lg:py-5 py-2 relative">
          <button
            onClick={goBack}
            className="absolute sm:left-16 left-6 sm:top-12 top-6"
          >
            <img src={backIcon} alt="back" className="lg:w-10 w-7" />
          </button>
          <div className="sm:mt-9 mt-16">
            <h1 className="text-center lg:text-3xl sm:text-2xl text-md font-bold">
              Checkout to access the Draw Ink Paper Premium Content
            </h1>
          </div>
          <div className="sm:mt-4 mt-1 mx-auto text-center sm:text-lg text-sm sm:w-[70vw] sm:px-0 px-7">
            <p>
              Enjoy unlimited access to our premium content with a subscription.
              Choose a plan (Annual or Lifetime) that works best for you, and
              start creating today! Check out with your preferred email address
              and signup/sign-in with same email id to enjoy premium experience.
              Reach out to us at hello@drawinkpaper.com if you need any help.
              We're an email away.
            </p>
          </div>
        </div>
      </div>
      <div
        className="sm:py-1 py-5 bg-[#53d5fd]"
        data-aos="fade-up"
        data-aos-delay="200"
      ></div>
      <Helmet>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
      </Helmet>
      <div className="bg-[#53d5fd]">
        <div
          className="sm:border-2 sm:border-dashed sm:border-secondary sm:w-[70vw] w-[90vw] mx-auto"
          dangerouslySetInnerHTML={{
            __html: `
          <stripe-pricing-table pricing-table-id="${stripePricingTableId}"
          publishable-key="${stripePublishableKey}">
          </stripe-pricing-table>
        `,
          }}
        />
      </div>
      <div className="bg-[#53d5fd] py-12"></div>
      <Footer />
    </div>
  );
};

export default CheckoutForm;
