/* eslint-disable */
import { useState, useEffect } from "react";
import { account, databases } from "../appwrite/appwriteConfig";
import { Query } from "appwrite";

const CheckPremiumStatus = ({ children }) => {
  const [isPremiumUser, setIsPremiumUser] = useState(true);
  const [plan, setPlan] = useState("Loading...");
  const [provider, setProvider] = useState("Loading...");
  const dbId = process.env.REACT_APP_APPWRITE_DATABASE_ID;
  const collectionId = process.env.REACT_APP_STRIPE_PAYMENT_COLLECTION_ID;

  useEffect(() => {
    const checkPremiumStatus = async () => {
      try {
        const session = await account.getSession("current");
        if (session) {
          // If a session exists, then the user is logged in
          setProvider(session.provider);
          const userId = session.providerUid;
          const data = await databases.listDocuments(dbId, collectionId, [
            Query.equal("customer_email", userId),
          ]);
          setPlan(data.documents[0].plan_purchased);
          if (data.documents.length === 0) setIsPremiumUser(false);
        }
      } catch (error) {
        setIsPremiumUser(false);
      }
    };

    checkPremiumStatus();
  }, [dbId, collectionId, isPremiumUser]);

  return children(isPremiumUser, plan, provider);
};

export default CheckPremiumStatus;
