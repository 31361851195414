import { SketchPicker } from "react-color";
import Draggable from "react-draggable";
import { popularColors } from "../utils/popularColors";

const ColorPalette = ({
  setCurrentColor,
  currentColor,
  isPaletteVisible,
  setPaletteVisibility,
}) => {
  const handleColorChange = (color) => {
    setCurrentColor(color);
  };
  return (
    <>
      <Draggable
        disabled={!isPaletteVisible} // Disable dragging when the palette is not visible
        cancel=".no-drag" //Exclude elements with the class "no-drag" from dragging
      >
        <div
          className={`bg-secondary sm:block sm:h-full lg:p-9 sm:p-4 p-2 sm:w-[24vw] lg:w-[20vw] w-[80vw] rounded-xl text-center
      ${isPaletteVisible ? "absolute right-8 bottom-0 h-[40vh]" : "hidden"}`}
        >
          <h1 className="lg:text-2xl sm:text-xl text-lg font-bold text-textColor">
            Color Picker
          </h1>
          <button
            className="sm:hidden bg-white absolute right-4 top-4 text-sm border rounded-full px-1 no-drag"
            onClick={() => setPaletteVisibility(false)}
          >
            X
          </button>
          <div className="hidden sm:grid grid-cols-3 gap-4 mt-7">
            {popularColors.map((colorInfo, index) => (
              <div
                key={index}
                style={{ backgroundColor: colorInfo.color }}
                className="p-6 rounded-xl cursor-pointer"
                onClick={() => handleColorChange(colorInfo.color)}
              ></div>
            ))}
          </div>
          <div className="sm:mt-9 flex items-center justify-center">
            <SketchPicker
              className="m-auto mt-4 cursor-pointer"
              color={currentColor}
              onChangeComplete={(e) => handleColorChange(e.hex)}
            />
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default ColorPalette;
