import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { handleSignOut } from "../utils/authUtils";
import useUserData from "../utils/useUserData";
import DrawingTutorialModal from "./DrawingTutorialModal";
import LOGO from "../assets/New_Logo.png";
import CheckPremiumStatus from "./CheckPremiumStatus";

const Header = ({ setSearchQuery, category, searchQuery, showSearchBar }) => {
  // Function to track button clicks with Google Analytics
  const trackButtonClick = (buttonName) => {
    ReactGA.event({
      category: "Button Click",
      action: `Clicked ${buttonName} Button`,
    });
  };

  // Get current path
  const path = window.location.pathname;

  // check user session
  const isUserLoggedIn = localStorage.getItem("cookieFallback");

  // Get user avatar
  const avatar = useUserData().avatar;

  // States to show/hide modal
  const [showModal, setShowModal] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isProductDropdown, setProductDropdown] = useState(false);

  // Refs for dropdowns
  const dropdownRef = useRef(null);
  const productDropdownRef = useRef(null);

  //CheckUserPremium
  const [isPremium, setPremium] = useState(true);

  // Function to close dropdown on scroll
  const closeDropdownOnScroll = () => {
    setDropdownOpen(false);
    setProductDropdown(false);
  };

  // Function to close dropdown when clicking outside
  const closeDropdownOnClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
    if (
      productDropdownRef.current &&
      !productDropdownRef.current.contains(event.target)
    ) {
      setProductDropdown(false);
    }
  };

  // Effect to add click event listener
  useEffect(() => {
    document.addEventListener("click", closeDropdownOnClickOutside);
    window.addEventListener("scroll", closeDropdownOnScroll);
    return () => {
      document.removeEventListener("click", closeDropdownOnClickOutside);
      window.removeEventListener("scroll", closeDropdownOnScroll);
    };
  }, []);

  return (
    <>
      <CheckPremiumStatus>
        {(isPremiumUser) => setPremium(isPremiumUser)}
      </CheckPremiumStatus>
      <div className="flex justify-between items-center sm:p-4 px-1 py-4 bg-secondary">
        <div>
          <Link to="/">
            <img src={LOGO} alt="logo" className="lg:w-32 md:w-28 w-24" />
          </Link>
        </div>
        <div className="flex items-center justify-between space-x-[2px] md:space-x-[2px] lg:space-x-2">
          <Link to="/generate-ai-drawing">
            <button
              className="bg-primary text-secondary rounded-lg hover:opacity-80 lg:px-3 lg:py-2 px-1 py-1 text-xs lg:text-lg lg:block hidden"
              onClick={() => trackButtonClick("Create Drawing with AI")}
            >
              Create Drawing with AI
            </button>
          </Link>
          {showSearchBar && (
            <div className="sm:block hidden">
              <input
                type="text"
                value={searchQuery}
                placeholder="Search for Drawings"
                className="border-[1px] border-textColor lg:p-3 md:px-2 md:py-1 px-2 py-1 rounded-lg lg:w-80 md:w-40 w-16 text-sm lg:text-md"
                onChange={(event) => setSearchQuery(event.target.value)}
              />
            </div>
          )}

          <button
            type="button"
            className="bg-primary text-secondary rounded-lg hover:opacity-80 lg:px-3 lg:py-2 p-1 text-sm lg:text-lg sm:block hidden"
            onClick={() => {
              setShowModal(true);
            }}
          >
            🎨Ask for a drawing tutorial!
          </button>
          <button
            className="bg-btn2 text-secondary lg:hidden text-sm md:text-md p-1 md:px-2 md:py-1 rounded-lg hover:opacity-80"
            onClick={() => {
              setProductDropdown(!isProductDropdown);
            }}
            ref={productDropdownRef}
          >
            Products
          </button>
          {/* drawtutorial and colorcanvas button for desktop */}
          {(path === "/populardrawings/all" ||
            path === "/populardrawings/all/draw" ||
            path === "/populardrawings/all/color") && (
            <Link to="/populardrawings/all/draw">
              <button className="bg-btn2 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Drawing Tutorials
              </button>
            </Link>
          )}
          {(path === "/color" ||
            path === "/draw" ||
            path === "/" ||
            path === "/allcategories") && (
            <Link to="/draw">
              <button className="bg-btn2 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Drawing Tutorials
              </button>
            </Link>
          )}
          {(path === `/subcategory/${category}` ||
            path === `/subcategory/${category}/draw` ||
            path === `/subcategory/${category}/color`) && (
            <Link to={`/subcategory/${category}/draw`}>
              <button className="bg-btn2 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Drawing Tutorials
              </button>
            </Link>
          )}
          {!(
            path === "/populardrawings/all" ||
            path === "/populardrawings/all/draw" ||
            path === "/populardrawings/all/color" ||
            path === "/color" ||
            path === "/draw" ||
            path === "/" ||
            path === "/allcategories" ||
            path === `/subcategory/${category}` ||
            path === `/subcategory/${category}/draw` ||
            path === `/subcategory/${category}/color`
          ) && (
            <Link to="/draw">
              <button className="bg-btn2 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Drawing Tutorials
              </button>
            </Link>
          )}
          {(path === "/populardrawings/all" ||
            path === "/populardrawings/all/draw" ||
            path === "/populardrawings/all/color") && (
            <Link to="/populardrawings/all/color">
              <button className="bg-btn1 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Coloring Canvas
              </button>
            </Link>
          )}
          {(path === "/" ||
            path === "/draw" ||
            path === "/color" ||
            path === "/allcategories") && (
            <Link to="/color">
              <button className="bg-btn1 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Coloring Canvas
              </button>
            </Link>
          )}
          {(path === `/subcategory/${category}` ||
            path === `/subcategory/${category}/draw` ||
            path === `/subcategory/${category}/color`) && (
            <Link to={`/subcategory/${category}/color`}>
              <button className="bg-btn1 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Coloring Canvas
              </button>
            </Link>
          )}
          {/* all other urls other than above should redirect to /color page */}
          {!(
            path === "/populardrawings/all" ||
            path === "/populardrawings/all/draw" ||
            path === "/populardrawings/all/color" ||
            path === "/color" ||
            path === "/draw" ||
            path === "/" ||
            path === "/allcategories" ||
            path === `/subcategory/${category}` ||
            path === `/subcategory/${category}/draw` ||
            path === `/subcategory/${category}/color`
          ) && (
            <Link to="/color">
              <button className="bg-btn1 px-3 rounded-lg py-2 text-lg text-secondary hidden lg:block hover:opacity-80">
                Coloring Canvas
              </button>
            </Link>
          )}

          {isProductDropdown && (
            <div className="absolute top-[60px] right-[110px] sm:mt-3 bg-secondary rounded-lg shadow-md z-10 md:right-52 sm:top-13 sm:text-base text-sm">
              <ul className="sm:px-5 px-3 py-3">
                <li className="hover:bg-tertiary text-center mb-2">
                  {(path === "/populardrawings/all" ||
                    path === "/populardrawings/all/draw" ||
                    path === "/populardrawings/all/color") && (
                    <Link to="/populardrawings/all/draw">
                      <button>How to Draw</button>
                    </Link>
                  )}
                  {(path === "/color" ||
                    path === "/draw" ||
                    path === "/" ||
                    path === "/allcategories") && (
                    <Link to="/draw">
                      <button>How to Draw</button>
                    </Link>
                  )}
                  {(path === `/subcategory/${category}` ||
                    path === `/subcategory/${category}/draw` ||
                    path === `/subcategory/${category}/color`) && (
                    <Link to={`/subcategory/${category}/draw`}>
                      <button>How to Draw</button>
                    </Link>
                  )}
                  {!(
                    path === "/populardrawings/all" ||
                    path === "/populardrawings/all/draw" ||
                    path === "/populardrawings/all/color" ||
                    path === "/color" ||
                    path === "/draw" ||
                    path === "/" ||
                    path === "/allcategories" ||
                    path === `/subcategory/${category}` ||
                    path === `/subcategory/${category}/draw` ||
                    path === `/subcategory/${category}/color`
                  ) && (
                    <Link to="/draw">
                      <button>How to Draw</button>
                    </Link>
                  )}
                </li>
                <li className="hover:bg-tertiary">
                  {(path === "/populardrawings/all" ||
                    path === "/populardrawings/all/draw" ||
                    path === "/populardrawings/all/color") && (
                    <Link to="/populardrawings/all/color">
                      <button>Coloring Canvas</button>
                    </Link>
                  )}
                  {(path === "/" ||
                    path === "/draw" ||
                    path === "/color" ||
                    path === "/allcategories") && (
                    <Link to="/color">
                      <button>Coloring Canvas</button>
                    </Link>
                  )}
                  {(path === `/subcategory/${category}` ||
                    path === `/subcategory/${category}/draw` ||
                    path === `/subcategory/${category}/color`) && (
                    <Link to={`/subcategory/${category}/color`}>
                      <button>Coloring Canvas</button>
                    </Link>
                  )}
                  {/* all other urls other than above should redirect to /color page */}
                  {!(
                    path === "/populardrawings/all" ||
                    path === "/populardrawings/all/draw" ||
                    path === "/populardrawings/all/color" ||
                    path === "/color" ||
                    path === "/draw" ||
                    path === "/" ||
                    path === "/allcategories" ||
                    path === `/subcategory/${category}` ||
                    path === `/subcategory/${category}/draw` ||
                    path === `/subcategory/${category}/color`
                  ) && (
                    <Link to="/color">
                      <button>Coloring Canvas</button>
                    </Link>
                  )}
                </li>
                {/* create drawing with AI option */}
                <li className="hover:bg-tertiary text-center mt-2">
                  <Link to="/generate-ai-drawing">
                    <button>AI Drawing</button>
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div className="flex space-x-1 sm:space-x-2">
            {!isPremium && (
              <Link to="/checkout">
                <button
                  className="bg-btn3 text-secondary lg:px-3 lg:py-2 p-1 rounded-lg text-sm md:text-md lg:text-lg hover:opacity-80"
                  onClick={() => trackButtonClick("Remove Ads")}
                >
                  Remove Ads
                </button>
              </Link>
            )}
            {!isUserLoggedIn && (
              <>
                <Link to="/login">
                  <button
                    className="bg-primary text-secondary px-2 py-1 lg:px-3 lg:py-2 md:px-2 md:py-1 rounded-lg hover:opacity-80 lg:text-lg md:text-md text-sm"
                    onClick={() => trackButtonClick("Coloring Canvas")}
                  >
                    Login
                  </button>
                </Link>
              </>
            )}
          </div>
          {isUserLoggedIn && (
            <>
              <div
                className="lg:pr-5 sm:mt-[-2px] sm:relative"
                ref={dropdownRef}
                onClick={() => {
                  setDropdownOpen(!isDropdownOpen);
                }}
              >
                <img
                  src={avatar}
                  alt="user"
                  className="lg:w-14 w-9 md:w-11 rounded-full object-cover border-[2px] border-tertiary cursor-pointer"
                />
                {isDropdownOpen && (
                  <div className="absolute bg-secondary w-[28vw] sm:w-[14vw] text-center top-13 right-0 mt-3 bg-white border-[2px] border-tertiary rounded-lg shadow-md z-10 cursor-pointer text-sm sm:text-base">
                    <ul className="p-3">
                      <li className="mb-2 hover:bg-tertiary">
                        <Link to="/profile">My Profile</Link>
                      </li>
                      <li
                        className="hover:bg-tertiary"
                        onClick={() => {
                          handleSignOut().then(() => {
                            window.location.href = "/";
                          });
                        }}
                      >
                        Sign Out
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <DrawingTutorialModal
        isOpen={showModal}
        closeModal={() => {
          setShowModal(false);
        }}
      />
    </>
  );
};

export default Header;
