import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import Popular from "../components/Popular";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import PremiumAd from "../components/PremiumAd";
import allCategories from "../utils/allCategories";
import filterDrawings from "../utils/searchData";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode, Autoplay, Navigation } from "swiper/modules";
import categoryDrawings from "../utils/categoryDrawings";

const AllCategories = () => {
  // Google Analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // states for filtering the data
  const [searchQuery, setSearchQuery] = useState("");
  // filtered data
  const filteredData = filterDrawings(searchQuery);

  return (
    <>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              🎨 Explore Art Ad-Free! Unlimited tutorials, exclusive coloring
              pages, and ebooks await with Premium.
            </span>
            <span className="sm:hidden">🎨 Explore Art Ad-Free!</span>
          </>
        }
        cta="Get Premium!"
      />
      <Header
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        showSearchBar={true}
        category=""
      />
      <div className="lg:mt-12 mt-9 sm:px-20 px-5 min-h-screen">
        <SubHeader
          category="All Categories"
          categoryDescription="Pick Your Favorite and Let's Create Art!"
          isCategoryBtnVisible={false}
          setSearchQuery={setSearchQuery}
          showSearchBar={true}
        />
        {searchQuery && (
          <div className="sm:mb-14">
            <h1 className="sm:text-2xl text-xl lg:mt-9 mt-7 font-bold text-textColor">
              Search Results
            </h1>
            {filteredData.length === 0 ? (
              <>
                <div className="flex justify-center mt-3">
                  <p className="sm:text-xl text-textColor">
                    No results found for "{searchQuery}"
                  </p>
                </div>
              </>
            ) : (
              <div className="lg:mt-7 mt-3 grid sm:grid-cols-4 grid-cols-2 lg:gap-7 gap-3">
                {filteredData.map((drawing) => (
                  <Popular key={drawing.title} item={drawing} word="draw" />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="lg:mt-7 mt-5 lg:mb-28 mb-14">
          {allCategories.map((category) => (
            <>
              <h1 className="lg:text-3xl sm:text-2xl text-lg font-bold text-textColor">
                {category.category}
              </h1>
              <Swiper
                breakpoints={{
                  340: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                  },
                  700: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                }}
                freeMode={true}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                navigation
                loop={true}
                modules={[FreeMode, Autoplay, Navigation]}
              >
                {categoryDrawings(category.category)?.subCategories?.map(
                  (subcategory) => (
                    <SwiperSlide key={subcategory.id} className="my-4">
                      <Popular item={subcategory} word="draw" />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllCategories;
