import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import Popular from "../components/Popular";
import Footer from "../components/Footer";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import seodata from "../utils/seodata.json";
import trendingDrawings from "../utils/trendingDrawings";
import filterDrawings from "../utils/searchData";

const PopularDrawings = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // states for filtering the data
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filterDrawings(searchQuery);

  //State to hide/show sidebar in mobile view
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  // get path word
  const path = window.location.pathname.split("/");
  let word = path[path.length - 1];
  if (word === "all") word = "draw";

  return (
    <>
      <Helmet>
        <title>{seodata.populardrawingspage.seotitle}</title>
        <meta
          name="description"
          content={seodata.populardrawingspage.seometadescription}
        />
        <meta
          name="keywords"
          content={seodata.populardrawingspage.seokeywords.join(", ")}
        />
        <h1>{seodata.populardrawingspage.seoh1}</h1>
        <h2>{seodata.populardrawingspage.seoh2.join(", ")}</h2>
        <body>{seodata.populardrawingspage.seobody}</body>
      </Helmet>
      <Header
        setSearchQuery={setSearchQuery}
        showSearchBar={true}
        category=""
      />
      <div className="lg:mt-12 mt-9 sm:px-20 px-5 min-h-screen">
        {word === "draw" && (
          <SubHeader
            category="Top Drawing picks by all Artists"
            categoryDescription="Pick Your Favorite and Start Drawing"
            isCategoryBtnVisible={false}
            setSearchQuery={setSearchQuery}
            showSearchBar={true}
          />
        )}
        {word === "color" && (
          <SubHeader
            category="Popular Coloring Pages"
            categoryDescription="Pick Your Favorite and Start Coloring"
            isSidebarVisible={isSidebarVisible}
            setSidebarVisibility={setSidebarVisibility}
          />
        )}
        {searchQuery && (
          <div className="sm:mb-16">
            <h1 className="sm:text-2xl text-xl lg:mt-9 mt-7 font-bold text-textColor">
              Search Results
            </h1>
            {filteredData.length === 0 ? (
              <>
                <div className="flex justify-center mt-3">
                  <p className="sm:text-xl text-textColor">
                    No results found for "{searchQuery}"
                  </p>
                </div>
              </>
            ) : (
              <div className="lg:mt-7 mt-3 grid sm:grid-cols-4 grid-cols-2 lg:gap-7 gap-3">
                {filteredData.map((drawing) => (
                  <Popular key={drawing.title} item={drawing} word={word} />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="lg:mt-7 mt-5 lg:mb-28 mb-14 grid sm:grid-cols-4 grid-cols-2 lg:gap-7 gap-3">
          {trendingDrawings.map((item) => (
            <Popular
              key={item.title}
              isPremium={item.isPremium}
              item={item}
              word={word}
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PopularDrawings;
