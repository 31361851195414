/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Modal from "../components/Modal";
import KidsAlsoLike from "../components/KidsAlsoLike";
import adsConfig from "../utils/adsConfig";
import CheckPremiumStatus from "../components/CheckPremiumStatus";
import { account, storage, functions } from "../appwrite/appwriteConfig";
import PremiumAd from "../components/PremiumAd";
import seodata from "../utils/seodata.json";
import loadingGif from '../assets/spinnergif.gif'; // Adjust the path to the actual location of your GIF
import AOS from "aos";
import "aos/dist/aos.css";



const GenerateDrawingAI = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [generatedImage, setGeneratedImage] = useState('');
  const [userId, setUserId] = useState('anonymous');
  const [showAuthPrompt, setShowAuthPrompt] = useState(false);
  const isUserLoggedIn = localStorage.getItem("cookieFallback");
  const [username, setUsername] = useState("");
  const [apiError, setApiError] = useState(false); // New state to track API errors
  const retryPeriodInSeconds = 20; // Retry after 5 seconds
  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.href = generatedImage;
    link.setAttribute('download', 'DRAWINKPAPER.COM_AI_Generated_Drawing.jpg'); // Set the download attribute
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

 
  useEffect(() => {
    if (isUserLoggedIn) {
      const promise = account.get();
      promise.then(
        function (response) {
            setUserId(response.$id);
        },
        function (error) {
          console.log(error);
        }
      );
    }
  }, [isUserLoggedIn]);
 
  const handleGenerateImage = async () => {
    if (!isUserLoggedIn) {
        setShowAuthPrompt(true);
        return;
      }
    setIsLoading(true);
    setApiError(false); // Reset the API error state before trying

    //const functionEndpoint = process.env.REACT_APP_APPWRITE_GENERATEDRAWING_ENDPOINT;; 
    const functionEndpoint = 'https://api.drawinkpaper.com/v1/functions/657c0be988da88359bdb/executions'; 
    const payload = {
      body: JSON.stringify({ prompt: prompt, user_id: userId }),
      async: false,
      path: "/",
      method: "GET",
      headers: {}
    };
  
    let attempts = 0;
  
    while (attempts < 5) {
      try {
        // Make the POST request to the function's endpoint
        const jwtResponse = await account.createJWT();
        const jwt = jwtResponse.jwt;
        const response = await fetch(functionEndpoint, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'X-Appwrite-Project': process.env.REACT_APP_APPWRITE_PROJECT_ID,
            'X-Appwrite-JWT': jwt

          },
          body: JSON.stringify(payload)
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        // Extract the JSON body content from the response
        const data = await response.json();
  
        // Use the file details from the response
        const responseBody = JSON.parse(data.responseBody);
        const { bucket_id, file_id } = responseBody;
      
        if (bucket_id && file_id) {
          // Use Appwrite SDK to get the file preview
          const result = await storage.getFilePreview(
            bucket_id,
            file_id,
            1800,
            0,
            'center',
            '90',
            5,
            'CDCA30',
            15,
            1,
            0,
            'FFFFFF',
            'jpg'
          );
          setGeneratedImage(result.href);
          setImageLoaded(false);
          setIsLoading(false); // Stop loading since the image was generated
          return; // Exit the function since the request was successful
        }
      } catch (error) {
        attempts += 1; // Increment the attempts counter
        console.error('Attempt', attempts, 'failed with error:', error);
        if (attempts < 5) {
          await new Promise(resolve => setTimeout(resolve, retryPeriodInSeconds * 1000)); // Wait for the specified period before retrying
        } else {
          setApiError(true); // Set the API error state after final attempt
          setIsLoading(false); // Stop loading after all attempts
        }
      }
    } 
  };
    

  return (
    <>
      
      <CheckPremiumStatus>
        {(isPremiumUser) => (
          <Helmet>
            <title>{seodata.generateaidrawingpage.seotitle}</title>
            <meta
              name="description"
              content={seodata.generateaidrawingpage.seometadescription}
            />
            <meta
              name="keywords"
              content={seodata.generateaidrawingpage.seokeywords.join(", ")}
            />
            <h1>{seodata.generateaidrawingpage.seoh1}</h1>
            <h2>{seodata.generateaidrawingpage.seoh2.join(", ")}</h2>
            <body>{seodata.generateaidrawingpage.seobody}</body>
            {!isPremiumUser && (
              // Scripts for non-premium users
              <script
                async
                src={adsConfig.googleAdSense.src}
                crossorigin={adsConfig.googleAdSense.crossorigin}
              ></script>
            )}
          </Helmet>
        )}
      </CheckPremiumStatus>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              🌟 Go Premium: No Ads, More Art! Unlock all tutorials, enjoy an
              ad-free canvas, exclusive coloring pages, and free ebooks.
            </span>
            <span className="sm:hidden">🌟 Go Premium: No Ads, More Art!</span>
          </>
        }
        cta="Upgrade Now!"
      />
      <Header showSearchBar={false} />
      <div className="container mx-auto my-10 p-5 text-center">
        <h1 className="text-3xl font-bold mb-5">Transform Words into Art: AI-Powered Drawing Generator</h1>
        <p className="mb-5">Step into the future of art creation with our AI Drawing Generator. Whether you're a professional artist or a beginner, our tool empowers you to transform simple text prompts into stunning visual masterpieces. From detailed landscapes to imaginative creatures, experience the ease of generating unique drawings using the power of AI. Our user-friendly platform is designed for everyone, making art creation accessible, fun, and free. Explore the unlimited potential of AI to bring your artistic visions to life</p>
        <p className="text-xl mb-5">Write a few words about what drawing would you like to generate today and click Generate. Add as much as detail you can to get the best results</p>
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="What would you like to draw?"
          className="border p-3 w-full mb-5 text-lg"
        />
        {/* Error message */}
        {apiError && (
          <div className="text-center my-4 p-3 bg-red-100 text-red-700 border border-red-200 rounded">
            AI isn't working at the moment. Please try again after few minutes, AI may be tired.
          </div>
        )}
          {isLoading && (
          <div className="flex flex-col items-center justify-center">
            <img src={loadingGif} alt="Loading" className="w-30 h-30 mb-5" />
            <p>Generating your personal drawing, AI is working hard. Please wait for 1-2 minutes.</p>
          </div>
        )}
                <button
          onClick={handleGenerateImage}
          disabled={isLoading}
          className="bg-btn1 text-secondary px-3 py-2 rounded-lg hover:opacity-80 text-base font-bold transition duration-300 ease-in-out"
        >
          {!isLoading && "Generate Drawing"}
        </button>
        {showAuthPrompt && (
  <div className="fixed inset-0 z-50 flex items-center justify-center">
    <div className="fixed inset-0 bg-black bg-opacity-50"></div>
    <div className="relative p-6 border border-gray-300 rounded-lg shadow-lg" style={{ backgroundColor: 'white' }}>
      <h3 className="mb-4 text-xl font-bold text-gray-900">Please sign-in or signup to generate AI Drawing</h3>
      <p className="mb-6 text-gray-600">Generating AI drawings is only allowed for signed-in users. Signup for free, no credit card required.</p>
      <div className="flex justify-center gap-4">
        <Link
          to="/signup"
          className="mt-4 px-3 py-1 text-white bg-blue-500 hover:bg-blue-600 rounded font-bold border border-blue-500 hover:border-blue-600" 
        >
          Sign Up
        </Link>
        <Link
          to="/login"
          className="mt-4 px-3 py-1 text-white bg-blue-500 hover:bg-blue-600 rounded font-bold border border-blue-500 hover:border-blue-600" 
        >
          Login
        </Link>
      </div>
      <button
        onClick={() => setShowAuthPrompt(false)}
        className="mt-4 px-3 py-1 text-white bg-gray-500 hover:bg-gray-600 rounded font-bold border border-gray-500 hover:border-gray-600" 
      >
        Close
      </button>
    </div>
  </div>
)}
{generatedImage && !imageLoaded && (
    <div className="flex flex-col items-center justify-center">
    <img src={loadingGif} alt="Loading" className="w-30 h-30 mb-5" /> {/* Adjust the size as needed */}
    <p>AI Artist has completed your drawing, Loading the image, may take upto a minute!.</p>
  </div>
)}
 {/* Display the "Download Image" button and the generated image */}
{/* Download Image button, only shown when there is an image and it's not loading */}
{!isLoading && generatedImage && (
        <div className="mt-4"> {/* Add margin-top for spacing */}
          <button
            onClick={handleDownloadImage}
            className="bg-btn1 text-secondary px-3 py-2 rounded-lg hover:opacity-80 text-base font-bold transition duration-300 ease-in-out"
            >
            Download Image
          </button>
        </div>
      )}

      {/* Display the generated image */}
      {generatedImage && (
        <div className="mt-5">
          <img
            src={generatedImage}
            alt="Generated AI Drawing"
            onLoad={() => setImageLoaded(true)}
            style={{ display: imageLoaded ? 'block' : 'none' }}
          />
        </div>
      )}
      </div>
      <KidsAlsoLike />
      <Footer />
    </>
  );
};

export default GenerateDrawingAI;
