import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const RefundPolicy = () => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Header />
      <div
        className="flex justify-between sm:space-x-20 mt-12 sm:mb-20 mb-8 lg:px-20 sm:px-10 px-5"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute sm:left-14 left-4 sm:top-auto top-[-0.5rem]"
        >
          <img src={backIcon} alt="back" className="sm:w-10 w-7" />
        </button>
        <div className="w-full bg-secondary rounded-lg sm:px-5 px-2 sm:pt-11 pt-5 sm:pb-16 pb-8">
          <div className="text-center">
            <h2 className="sm:text-2xl text-lg font-bold text-textColor">
              Refund Policy
            </h2>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2">
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Our Commitment
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              At DrawInkPaper, we are committed to ensuring the satisfaction of
              our customers. If you are not satisfied with your purchase, we
              offer a refund policy to address your concerns.
            </p>

            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Refund Eligibility
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              We offer refunds if a request is made within 24 hours of the
              purchase. Unfortunately, we are unable to issue refunds beyond
              this 24-hour period. To request a refund, please contact us within
              this timeframe at{" "}
              <a href="mailto:hello@drawinkpaper.com" className="text-primary">
                hello@drawinkpaper.com
              </a>{" "}
              with your order details.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Refund Time Frame
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              Customers are eligible for a full refund within 24 hours of their
              purchase. To initiate a refund, please contact us at{" "}
              <a href="mailto:hello@drawinkpaper.com" className="text-primary">
                hello@drawinkpaper.com
              </a>{" "}
              with your order details.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Refund Process
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              Upon receiving your refund request, we will process it promptly.
              Refunds will be issued to the original method of payment. Please
              allow a few business days for the transaction to reflect on your
              account.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Avoid Disputes
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              We kindly request our customers to avoid raising disputes with
              their banks or credit card companies. Direct communication with us
              is the most effective way to resolve any issues quickly. Raising
              disputes can prolong the process and complicate resolution.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Contact Us
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              For any questions regarding our refund policy or for assistance
              with a refund, please do not hesitate to contact us at{" "}
              <a href="mailto:hello@drawinkpaper.com" className="text-primary">
                hello@drawinkpaper.com
              </a>
              . We are here to help and ensure your satisfaction with our
              services.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Legal Disclaimer
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              The refund policy is subject to change without notice and at the
              sole discretion of DrawInkPaper. This policy does not affect any
              statutory rights that may be applicable.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Limitation of Liability
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              DrawInkPaper shall not be liable for any indirect, special,
              incidental, or consequential damages or any loss of revenue,
              profits, or data arising in connection with our services or this
              refund policy.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Governing Law
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              This refund policy shall be governed and construed in accordance
              with the laws of the jurisdiction in which DrawInkPaper operates,
              without regard to its conflict of law provisions.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Amendments to This Policy
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              DrawInkPaper reserves the right to modify this refund policy at
              any time. We recommend that users review this policy periodically
              to stay informed of any changes.
            </p>
            <h3 className="sm:text-xl text-md sm:mt-5 mt-2 font-bold text-textColor">
              Contact Information
            </h3>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              For any inquiries regarding the refund policy, please contact us
              at{" "}
              <a href="mailto:hello@drawinkpaper.com" className="text-primary">
                hello@drawinkpaper.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RefundPolicy;
