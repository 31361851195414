import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Popular from "../components/Popular";
import SubCategorySidebar from "../components/SubCategorySidebar";
import SubHeader from "../components/SubHeader";
import PremiumAd from "../components/PremiumAd";
import BreakinAd from "../components/BreakinAd";
import CheckPremiumStatus from "../components/CheckPremiumStatus";
import Coupon from "../components/Coupon";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import AOS from "aos";
import "aos/dist/aos.css";
import seodata from "../utils/seodata.json";
import adsConfig from "../utils/adsConfig";
import trendingDrawings from "../utils/trendingDrawings";
import allCategories from "../utils/allCategories";
import filterDrawings from "../utils/searchData";
import blogs from "../utils/blogs.json";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode, Autoplay, Navigation } from "swiper/modules";
import categoryDrawings from "../utils/categoryDrawings";

const Home = ({ word }) => {
  const [searchResult, setSearchResult] = useState("Search Result");
  // Google Analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // states for filtering the data
  const [searchQuery, setSearchQuery] = useState("");
  // filtered data
  const filteredData = filterDrawings(searchQuery);

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  //State to hide/show sidebar in mobile view
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  // State for managing the visibility of the Coupon modal
  const [showCouponModal, setShowCouponModal] = useState(false);

  // Effect to show the Coupon modal when the component mounts
  useEffect(() => {
    setShowCouponModal(true);
    return () => {
      setShowCouponModal(false);
    };
  }, []);

  // Scroll to top function
  const scrollToTop = () => {
    setSidebarVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Fixed button in left corner for mobile screen
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const match = allCategories.some(
      (category) =>
        category.category.toLowerCase() === searchQuery.toLowerCase()
    );
    setSearchResult(
      match
        ? searchQuery.charAt(0).toUpperCase() + searchQuery.slice(1)
        : "Search Result"
    );
  }, [searchQuery]);

  // Scroll to top button
  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 120;
      if (window.scrollY > scrollThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <CheckPremiumStatus>
        {(isPremiumUser) => (
          <>
            {!isPremiumUser && showCouponModal && (
              <Coupon onClose={() => setShowCouponModal(false)} />
            )}
          </>
        )}
      </CheckPremiumStatus>
      <CheckPremiumStatus>
        {(isPremiumUser) => (
          <Helmet>
            <title>{seodata.homepage.seotitle}</title>
            <meta
              name="description"
              content={seodata.homepage.seometadescription}
            />
            <meta
              name="keywords"
              content={seodata.homepage.seokeywords.join(", ")}
            />
            <h1>{seodata.homepage.seoh1}</h1>
            <h2>{seodata.homepage.seoh2.join(", ")}</h2>
            <body>{seodata.homepage.seobody}</body>
            {!isPremiumUser && (
              // Scripts for non-premium users
              <script
                async
                src={adsConfig.googleAdSense.src}
                crossorigin={adsConfig.googleAdSense.crossorigin}
              ></script>
            )}
          </Helmet>
        )}
      </CheckPremiumStatus>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              🌟 Go Premium: No Ads, More Art! Unlock all tutorials, enjoy an
              ad-free canvas, exclusive coloring pages, and free ebooks.
            </span>
            <span className="sm:hidden">🌟 Go Premium: No Ads, More Art!</span>
          </>
        }
        cta="Upgrade Now!"
      />
      <Header
        setSearchQuery={setSearchQuery}
        showSearchBar={true}
        searchQuery={searchQuery}
        category=""
        isSidebarVisible={isSidebarVisible}
        setSidebarVisibility={setSidebarVisibility}
      />
      <div
        className="flex sm:space-x-10 sm:p-9 px-5 sm:mt-3 mt-5 mb-9 justify-center"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <div>
          <SubCategorySidebar
            isSidebarVisible={isSidebarVisible}
            setSidebarVisibility={setSidebarVisibility}
          />
        </div>
        <div className="sm:w-[80vw] lg:px-12">
          <SubHeader
            isSidebarVisible={isSidebarVisible}
            setSidebarVisibility={setSidebarVisibility}
            setSearchQuery={setSearchQuery}
            isCategoryBtnVisible={true}
            showSearchBar={true}
          />
          {searchQuery && (
            <>
              <h1 className="lg:text-2xl sm:text-xl mt-9 font-bold text-textColor">
                {searchResult}
              </h1>
              {filteredData.length === 0 ? (
                <>
                  <div className="flex justify-center mt-3">
                    <p className="text-xl text-textColor">
                      No results found for "{searchQuery}"
                    </p>
                  </div>
                </>
              ) : (
                <div className="mt-5 grid sm:grid-cols-3 grid-cols-2 sm:gap-7 gap-3">
                  {filteredData.map((drawing) => (
                    <Popular
                      key={drawing.title}
                      item={drawing}
                      word={word ? word : "draw"}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          <div className="lg:mt-10 mt-7">
            <div className="flex justify-between">
              <h1 className="sm:text-2xl text-lg font-bold text-textColor">
                Popular Drawings
              </h1>
              <Link to="/populardrawings/all">
                <button className="bg-btn1 text-secondary px-2 lg:px-3 py-1 lg:py-2 rounded-lg hover:opacity-80 sm:text-base text-sm">
                  View More
                </button>
              </Link>
            </div>
            <div className="mt-5 grid sm:grid-cols-3 grid-cols-2 lg:gap-7 gap-3">
              {trendingDrawings.slice(0, 30).map((item, index) => (
                <>
                  <Popular
                    key={item.title}
                    isPremium={item.isPremium}
                    item={item}
                    word={word ? word : "draw"}
                  />
                  {index === 8 && (
                    <div className="col-span-full hidden sm:block">
                      <BreakinAd />
                    </div>
                  )}
                  {/* BreakinAd for mobile */}
                  {index === 5 && (
                    <div className="col-span-full sm:hidden">
                      <BreakinAd />
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div
            className="lg:mt-14 sm:mb-0 mt-12 mb-9"
            id="categories"
            data-aos="fade-up"
          >
            <div className="flex justify-between mt-3">
              <h1 className="lg:text-2xl text-lg font-bold text-textColor">
                All Categories
              </h1>
              <Link to="/allcategories">
                <button className="bg-btn1 text-secondary px-2 lg:px-3 py-1 lg:py-2 rounded-lg hover:opacity-80 sm:text-base text-sm">
                  View More
                </button>
              </Link>
            </div>
            <div className="mt-5 flex-col items-center justify-center">
              {allCategories.map((category) => (
                <div className="lg:w-[65vw] md:w-[50vw] w-[90vw]">
                  <h1 className="text-md font-bold text-textColor">
                    {category.category}
                  </h1>
                  <Swiper
                    breakpoints={{
                      340: {
                        slidesPerView: 2,
                        spaceBetween: 15,
                      },
                      700: {
                        slidesPerView: 3,
                        spaceBetween: 15,
                      },
                      1024: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                      },
                    }}
                    freeMode={true}
                    autoplay={{
                      delay: 1500,
                      disableOnInteraction: false,
                    }}
                    navigation
                    modules={[FreeMode, Autoplay, Navigation]}
                  >
                    {categoryDrawings(category.category)?.subCategories?.map(
                      (subcategory) => (
                        <SwiperSlide key={subcategory.id} className="my-4">
                          <Popular item={subcategory} word="draw" />
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:mt-14 sm:mb-0 mt-12 mb-9" data-aos="fade-up">
            <h1 className="lg:text-2xl text-lg font-bold text-textColor">
              Recent Blogs
            </h1>
            <div className="mx-auto lg:mt-12 mt-6">
              <Carousel
                showThumbs={false}
                showArrows={true}
                swipeable={true}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="arrow"
                      style={{ left: 10 }}
                    >
                      &#10094;
                    </button>
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <button
                      type="button"
                      onClick={onClickHandler}
                      title={label}
                      className="arrow"
                      style={{ right: 10 }}
                    >
                      &#10095;
                    </button>
                  )
                }
              >
                {blogs.blogs.map((blog) => (
                  <article
                    key={blog.id}
                    className="bg-secondary rounded-2xl mb-9 grid sm:grid-cols-3 grid-cols-1 w-full mx-auto"
                  >
                    <div className="col-span-1 mx-auto">
                      <img
                        src={blog.image}
                        alt={`blog + ${blog.title}`}
                        className="rounded-2xl w-full lg:h-64 md:h-40 h-32"
                      />
                    </div>
                    <div className="col-span-2 sm:p-7 p-4">
                      <h3 className="lg:text-2xl sm:text-xl font-semibold">
                        <Link
                          to={`/blog/${blog.title.replace(/\s+/g, "-")}`}
                          className="hover:text-btn1 text-textColor"
                        >
                          {blog.title}
                        </Link>
                      </h3>
                      <p className="sm:mt-3 mt-1 lg:text-lg sm:text-md sm:line-clamp-5 line-clamp-3">
                        {blog.description}
                      </p>
                      <Link to={`/blog/${blog.title.replace(/\s+/g, "-")}`}>
                        <h6 className="lg:text-lg text-md font-bold hover:text-btn1 cursor-pointer mt-3 text-textColor">
                          Read More
                        </h6>
                      </Link>
                    </div>
                  </article>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* Floating category button */}
      {showButton && (
        <button
          onClick={scrollToTop}
          className={`${
            isSidebarVisible ? "hidden" : "block"
          } sm:hidden fixed bottom-8 right-4 bg-btn2 text-secondary px-3 py-1 text-sm rounded-lg hover:opacity-80 border-2`}
          data-aos="fade-up"
        >
          Category
        </button>
      )}
      <Footer />
    </>
  );
};

export default Home;