import React, { useState, useRef } from 'react';
import { Stage, Layer, Line } from 'react-konva';

const OnlineDrawingCanvas = () => {
    const [tool, setTool] = useState('pen');
    const [lines, setLines] = useState([]);
    const [color, setColor] = useState('#000000');
    const [size, setSize] = useState(5);
    const stageRef = useRef();
    const isDrawing = useRef(false);

    const colors = ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#FF00FF', '#00FFFF'];

    const handleMouseDown = (e) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y], color, size }]);
    };

    const handleMouseMove = (e) => {
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        lastLine.points = lastLine.points.concat([point.x, point.y]);
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    const handleUndo = () => {
        const newLines = lines.slice(0, -1);
        setLines(newLines);
    };

    const handleSave = () => {
        const uri = stageRef.current.toDataURL();
        const link = document.createElement('a');
        link.download = 'sketchpad.png';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <div className="flex">
            <div className="flex flex-col items-center justify-center p-4 space-y-2 bg-gray-200">
                <div className="grid grid-cols-3 gap-2">
                    {colors.map((paletteColor, i) => (
                        <div key={i} className="w-8 h-8 rounded-full cursor-pointer"
                             style={{ backgroundColor: paletteColor }}
                             onClick={() => setColor(paletteColor)}></div>
                    ))}
                </div>
                <input type="color" value={color} onChange={(e) => setColor(e.target.value)} className="mb-2 w-8 h-8" />
                <input type="range" min="1" max="20" value={size} onChange={(e) => setSize(parseInt(e.target.value, 10))} className="w-full" />
                <button onClick={() => setTool('pen')} className="p-2 bg-gray-300">Pen</button>
                <button onClick={() => setTool('rect')} className="p-2 bg-gray-300">Rectangle</button>

                <button onClick={handleUndo} className="p-2 bg-gray-300">Undo</button>
                <button onClick={handleSave} className="p-2 bg-gray-300">Save</button>
            </div>
            <Stage
                width={800}
                height={600}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                ref={stageRef}
                style={{ border: '1px solid black', backgroundColor: 'white' }}>
                <Layer>
                    {lines.map((line, i) => (
                        <Line
                            key={i}
                            points={line.points}
                            stroke={line.color}
                            strokeWidth={line.size}
                            tension={0.5}
                            lineCap="round"
                            globalCompositeOperation={
                                line.tool === 'eraser' ? 'destination-out' : 'source-over'
                            }
                        />
                    ))}
                    {/* Add logic for drawing rectangles and circles */}
                </Layer>
            </Stage>
        </div>
    );
};

export default OnlineDrawingCanvas;