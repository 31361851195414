import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Popular from "../components/Popular";
import SubCategorySidebar from "../components/SubCategorySidebar";
import SubHeader from "../components/SubHeader";
import PremiumAd from "../components/PremiumAd";
import ReactGA from "react-ga4";
import AOS from "aos";
import "aos/dist/aos.css";
import categoryDrawings from "../utils/categoryDrawings";
import filterDrawings from "../utils/searchData";

const SubCategory = ({ word }) => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // Get the selected category from URL params
  const { category } = useParams();

  // state for filtering the data
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filterDrawings(searchQuery);

  // Get the drawings for the selected category
  const categoryDrawingsObject = categoryDrawings(category);

  //State to hide/show sidebar in mobile view
  const [isSidebarVisible, setSidebarVisibility] = useState(false);

  // Scroll to top function
  const scrollToTop = () => {
    setSidebarVisibility(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Fixed button in left corner for mobile screen
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 120;
      if (window.scrollY > scrollThreshold) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              {`🌈 Enhance ${category} drawing/ coloring skills Ad-Free! Premium offers uninterrupted tutorials, coloring pages, and ebooks.`}
            </span>
            <span className="sm:hidden">
              {`🌈 Enhance ${category} drawing/ coloring skills`}
            </span>
          </>
        }
        cta="Go Premium!"
      />
      <Header
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        showSearchBar={true}
        category={category}
        isSidebarVisible={isSidebarVisible}
        setSidebarVisibility={setSidebarVisibility}
      />
      <div
        className="flex sm:space-x-10 sm:p-9 px-5 sm:mt-3 mt-5 mb-9 justify-center"
        data-aos="fade-up"
      >
        <div>
          <SubCategorySidebar
            isSidebarVisible={isSidebarVisible}
            setSidebarVisibility={setSidebarVisibility}
          />
        </div>
        <div className="sm:w-[80vw] lg:px-12">
          <SubHeader
            category={category}
            categoryDescription={categoryDrawingsObject.subcategoryDescription}
            isSidebarVisible={isSidebarVisible}
            setSidebarVisibility={setSidebarVisibility}
            isCategoryBtnVisible={true}
            setSearchQuery={setSearchQuery}
            showSearchBar={true}
          />
          {searchQuery && (
            <div className="mb-12 mt-9">
              <h1 className="text-lg lg:text-2xl font-bold text-textColor">
                Search Results
              </h1>
              {filteredData.length === 0 ? (
                <>
                  <div className="flex justify-center mt-3">
                    <p className="text-xl text-textColor">
                      No results found for "{searchQuery}"
                    </p>
                  </div>
                </>
              ) : (
                <div className="mt-4 sm:mt-7 grid sm:grid-cols-3 grid-cols-2 sm:gap-7 gap-3">
                  {filteredData.map((drawing) => (
                    <Popular
                      key={drawing.title}
                      isPremium={drawing.isPremium}
                      item={drawing}
                      word={word ? word : "draw"}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
          <div className="lg:mt-7 mt-3">
            <div className="grid sm:grid-cols-3 grid-cols-2 lg:gap-7 gap-3">
              {categoryDrawingsObject.subCategories.map((subcategory) => (
                <Popular
                  isPremium={subcategory.isPremium}
                  key={subcategory.id}
                  item={subcategory}
                  word={word ? word : "draw"}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* Floating category button */}
      {showButton && (
        <button
          onClick={scrollToTop}
          className={`${
            isSidebarVisible ? "hidden" : "block"
          } sm:hidden fixed bottom-8 right-4 bg-btn2 text-secondary px-3 py-1 text-sm rounded-lg hover:opacity-80 border-2`}
          data-aos="fade-up"
        >
          Category
        </button>
      )}
      <Footer />
    </>
  );
};

export default SubCategory;
