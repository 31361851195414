import { useEffect } from "react";

const Ads = ({ image, isPreviewVisible, setPreviewVisibility, title }) => {
  // ads
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log(error);
    }
  }, []);

  // Ads client ID
  const adClientId = process.env.REACT_APP_DATA_AD_CLIENT_ID;

  return (
    <>
      {isPreviewVisible && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-blur-lg" />
      )}
      <div
        className={`bg-secondary sm:block sm:h-full p-9 sm:w-[20vw] w-[80vw] rounded-xl text-center
    ${isPreviewVisible ? "z-50 absolute right-8 top-8 h-[100vw]" : "hidden"}
    `}
      >
        <div>
          <h1 className="lg:text-xl text-lg sm:text-textColor text-[#0000FF] font-bold">
            {title.toUpperCase()} DRAWING PREVIEW
          </h1>
          <button
            className="sm:hidden bg-white absolute right-4 top-4 text-sm border rounded-full px-1"
            onClick={() => setPreviewVisibility(false)}
          >
            X
          </button>
          <img
            src={image}
            alt="preview"
            className="mt-3 border-[1px] border-textColor p-2 rounded-xl"
          />
          <div className="text-textColor text-xl font-bold sm:mt-9">
            <p className="sm:block sm:text-lg lg:text-xl hidden">
              👉 Remember, follow the red lines in each image to make your own
              masterpiece. Let's get started!
            </p>
          </div>
        </div>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={adClientId}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    </>
  );
};

export default Ads;
