import { Link, useNavigate } from "react-router-dom";
import CheckPremiumStatus from "./CheckPremiumStatus";
import useUserData from "../utils/useUserData";
import { handleSignOut } from "../utils/authUtils";
import ReactGA from "react-ga";

const ProfileCard = ({ onProgressClick, onSettingsClick }) => {
  // React Router Navigate
  const navigate = useNavigate();

  // Function to track button clicks with Google Analytics
  const trackButtonClick = (buttonName) => {
    ReactGA.event({
      category: "Button Click",
      action: `Clicked ${buttonName} Button`,
    });
  };

  // Get user data
  const userData = useUserData();
  const { name, email, avatar } = userData;

  return (
    <CheckPremiumStatus>
      {(isPremiumUser, plan, provider) => (
        <div className="bg-secondary lg:w-[20vw] md:w-[40vw] w-[90vw] mx-auto h-fit rounded-xl cursor-pointer">
          <div className="mt-7 flex justify-center">
            <img
              src={avatar}
              alt="user"
              className="sm:w-[10vw] w-[30vw] rounded-full border-2 border-primary"
            />
          </div>
          <div className="mt-5 text-center sm:text-2xl text-xl text-textColor font-bold">
            <h1>{name}</h1>
          </div>
          <div className="mt-1 text-center sm:text-md text-sm text-textColor">
            <p>{email}</p>
          </div>
          <div className="mt-7 px-9 flex justify-between">
            <p
              className={`sm:text-xl text-lg text-btn2 mt-1 font-bold ${
                isPremiumUser ? "mx-auto" : ""
              }`}
            >
              {isPremiumUser ? plan : "Free Plan"}
            </p>
            {!isPremiumUser && (
              <Link to="/checkout">
                <button
                  className="bg-btn1 text-secondary p-2 rounded-lg hover:bg-btn2 sm:text-base text-md"
                  onClick={() => trackButtonClick("Premium")}
                >
                  Upgrade Plan
                </button>
              </Link>
            )}
          </div>
          <ul className="sm:mt-9 sm:mb-7 my-5 text-center">
            {isPremiumUser && (
              <Link to="https://billing.stripe.com/p/login/eVabLK9FY2rz5UYbII">
                <li className="sm:text-xl text-lg text-textColor hover:bg-tertiary px-5 py-3">
                  Manage Billing
                </li>
              </Link>
            )}
            <li
              className="sm:text-xl text-lg text-textColor hover:bg-tertiary px-5 py-3"
              onClick={onProgressClick}
            >
              View Progress
            </li>
            {provider === "email" && (
              <li
                className="sm:text-xl text-lg text-textColor hover:bg-tertiary px-5 py-3"
                onClick={onSettingsClick}
              >
                Manage Password
              </li>
            )}
            <li
              className="sm:text-xl text-lg text-textColor hover:bg-tertiary px-5 py-3"
              onClick={() => {
                handleSignOut();
                navigate("/");
              }}
            >
              Sign Out
            </li>
          </ul>
        </div>
      )}
    </CheckPremiumStatus>
  );
};

export default ProfileCard;
