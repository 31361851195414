import { account } from "../appwrite/appwriteConfig";
import { ID } from "appwrite";

// Function to Sign Out
const handleSignOut = async () => {
  try {
    await account.deleteSession("current");
    localStorage.removeItem("cookieFallback");
  } catch (error) {
    console.log(error);
  }
};

// Function to update password
const handlePasswordUpdate = async (
  currentPassword,
  newPassword,
  confirmNewPassword,
  setError,
  setLoading,
  setSuccess,
  navigate
) => {
  try {
    setLoading(true);
    if (newPassword === confirmNewPassword) {
      // Update password
      await account.updatePassword(newPassword, currentPassword);
      setSuccess(true);
      setError("");
      localStorage.removeItem("cookieFallback");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      setError("Please enter same password in both new password fields");
    }
  } catch (error) {
    if (
      error.message ===
      "Invalid credentials. Please check the email and password."
    ) {
      setError("Please enter correct current password");
    } else if (
      error.message ===
      "Invalid `oldPassword` param: Password must be at least 8 characters long"
    ) {
      setError("Current Password must be at least 8 characters");
    } else if (
      error.message ===
      "Invalid `password` param: Password must be at least 8 characters and should not be one of the commonly used password."
    ) {
      setError(
        "New Password must be at least 8 characters and should not be one of the commonly used password."
      );
    } else {
      setError(error.message);
    }
  } finally {
    setLoading(false);
  }
};

// Function to forget password
const forgetPassword = async (
  email,
  setLoading,
  setError,
  setAlert,
  navigate
) => {
  try {
    setLoading(true);
    await account.createRecovery(
      email,
      "https://drawinkpaper.com/resetPassword"
    );
    setAlert(
      "Password reset link sent to your email. Please follow the link to reset your password. Redirecting to Login Page..."
    );
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

// Function to Login
const loginUser = async (email, password, setLoading, setError, navigate) => {
  try {
    setLoading(true);
    const session = await account.createEmailSession(email, password);
    localStorage.setItem("cookieFallback", session.$id);
    window.location.href = "/";
  } catch (error) {
    if (
      error.message ===
      "Invalid `email` param: Value must be a valid email address"
    ) {
      setError("Please enter a valid email address");
    } else if (
      error.message ===
      "Invalid `password` param: Password must be at least 8 characters and should not be one of the commonly used password."
    ) {
      setError(
        "Password must be at least 8 characters and should not be one of the commonly used password."
      );
    } else if (
      error.message ===
      "Invalid credentials. Please check the email and password."
    ) {
      setError("User does not exist. Please signup.");
    } else {
      setError(error.message);
    }
  } finally {
    setLoading(false);
  }
};

// Function to generate some random hash for cookie fallback
const generateRandomHash = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let hash = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    hash += characters.charAt(randomIndex);
  }
  return hash;
};

// Function to handle Google authentication
const googleAuth = () => {
  try {
    const randomHash = generateRandomHash(100);
    account.createOAuth2Session(
      "google",
      "https://drawinkpaper.com/",
      "https://drawinkpaper.com/login"
    );
    localStorage.setItem("cookieFallback", randomHash);
    window.location.href="/";
  } catch (error) {
    console.log(error);
  }
};

// Function to Reset Password
const resetPassword = async (
  newPassword,
  newPasswordAgain,
  setLoading,
  setError,
  setAlert,
  navigate
) => {
  try {
    setLoading(true);
    if (newPassword !== newPasswordAgain) {
      setError("Please enter same password in both new password fields");
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const secret = urlParams.get("secret");
    await account.updateRecovery(userId, secret, newPassword, newPasswordAgain);
    setAlert("Password reset successful. Redirecting to Login Page...");
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  } catch (error) {
    setError(error.message);
  } finally {
    setLoading(false);
  }
};

// Function to Signup User
const signupUser = async (
  name,
  email,
  password,
  setLoading,
  setError,
  navigate
) => {
  try {
    setLoading(true);
    const response = await account.create(ID.unique(), email, password, name);
    localStorage.setItem("cookieFallback", response.$id);
    await account.createEmailSession(email, password);
    await account.createVerification("https://drawinkpaper.com/VerifyEmail");
    navigate("/");
  } catch (error) {
    if (
      error.message ===
      "A user with the same id, email, or phone already exists in this project."
    ) {
      setError(
        "This email id already exists please sign in or click forget password to get the password reset link on the registered email"
      );
    } else if (
      error.message ===
      "Invalid `email` param: Value must be a valid email address"
    ) {
      setError("Please enter a valid email address");
    } else if (
      error.message ===
      "Invalid `password` param: Password must be at least 8 characters and should not be one of the commonly used password."
    ) {
      setError(
        "Password must be at least 8 characters and should not be one of the commonly used password."
      );
    } else {
      setError(error.message);
    }
  } finally {
    setLoading(false);
  }
};

export {
  handleSignOut,
  handlePasswordUpdate,
  forgetPassword,
  loginUser,
  googleAuth,
  resetPassword,
  signupUser,
};
