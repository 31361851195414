import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import data from "../utils/data.json";
import AOS from "aos";
import "aos/dist/aos.css";

const SubCategorySidebar = ({ isSidebarVisible, setSidebarVisibility }) => {
  // AOS animation
  useEffect(() => {
    const initAOS = () => {
      AOS.init();
      AOS.refresh();
    };
    // Initialize AOS after the sidebar is visible
    if (isSidebarVisible) {
      initAOS();
    }
    // Listen for changes in isSidebarVisible and reinitialize AOS
    const onSidebarVisibilityChange = () => {
      if (isSidebarVisible) {
        initAOS();
      }
    };
    // Add event listener for changes in isSidebarVisible
    window.addEventListener("resize", onSidebarVisibilityChange);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", onSidebarVisibilityChange);
    };
  }, [isSidebarVisible]);

  // React router hook for navigation
  const navigate = useNavigate();

  // Get all the categories from the data
  const categories = [
    ...new Set(data.drawings.map((drawing) => drawing.category)),
  ];

  // State for managing the active button status
  const [activeCategory, setActiveCategory] = useState(null);

  // Function to handle the active status and update selected category
  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    navigate(`/subcategory/${category}`);
    setSidebarVisibility(false);
    // track event analytics
    ReactGA.event({
      category: "Button Click",
      action: `Clicked ${category} Button`,
    });
  };

  const goToAllCategory = () => {
    const allCategoriesSection = document.getElementById("categories");
    allCategoriesSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      {/* Gives blur background to sidebar in mobile view */}
      {isSidebarVisible && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-blur-lg" />
      )}
      <div
        className={`bg-tertiary min-h-full sm:p-9 p-7 lg:w-[20vw] sm:w-[32vw] w-[80vw] rounded-xl sm:block ${
          isSidebarVisible ? "block z-20 absolute right-10" : "hidden"
        }`}
        data-aos="fade-up"
      >
        <h1 className="text-center lg:text-2xl text-xl text-textColor font-bold sm:mt-0 mt-7 sm:mb-0 mb-3">
          Choose a Category
        </h1>
        <button
          className="sm:hidden bg-white absolute right-4 top-4 text-sm border rounded-full px-1"
          onClick={() => setSidebarVisibility(false)}
        >
          X
        </button>
        <div className="lg:mt-11 mt-6 text-center">
          <Link to="/premium-drawings/">
            <button className="lg:text-xl sm:text-lg text-md font-bold lg:p-4 p-3 rounded-xl sm:w-auto w-full sm:mb-7 mb-4 text-secondary bg-btn2">
              Premium Drawings
            </button>
          </Link>
          <button
            className="lg:text-xl sm:text-lg text-md font-bold lg:p-4 p-3 rounded-xl sm:w-48 w-full sm:mb-7 mb-4 text-secondary bg-primary"
            onClick={goToAllCategory}
          >
            All Categories
          </button>
          <Link to="/populardrawings/all">
            <button className="lg:text-xl sm:text-lg text-md font-bold lg:p-4 p-3 rounded-xl sm:w-48 w-full sm:mb-7 mb-4 text-secondary bg-primary">
              Popular Drawings
            </button>
          </Link>
          {categories.map((category) => (
            <button
              key={category}
              className={`lg:text-xl sm:text-lg text-md font-bold lg:p-4 p-3 rounded-xl sm:w-48 w-full sm:mb-7 mb-4 text-secondary ${
                activeCategory === category ? "bg-btn1" : "bg-primary"
              }`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubCategorySidebar;
