import data from "./data.json";

const categoryDrawings = (category) => {
  const subCategoriesForCategory = data.drawings.find(
    (drawing) => drawing.category === category
  );
  if (subCategoriesForCategory) {
    return {
      subCategories: subCategoriesForCategory.items,
      subcategoryDescription: subCategoriesForCategory.description,
    };
  }
  return [];
};

export default categoryDrawings;
