import { useRef, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CopyIcon from "../assets/copy.png";
import TickIcon from "../assets/tick.png";

const Coupon = ({ onClose }) => {
  // Initialize AOS
  useEffect(() => {
    AOS.init();
  }, []);

  const modalRef = useRef(null);
  const [copied, setCopied] = useState(false);

  // Close modal when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  // Copy coupon code to clipboard
  const handleCopyCoupon = () => {
    const couponCode = "EARLY50";
    const tempInput = document.createElement("input");
    tempInput.value = couponCode;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-20">
      <div
        ref={modalRef}
        className="coupon-bg rounded-xl shadow-xl text-center sm:w-auto w-[90vw]"
        data-aos="fade-down"
        data-aos-delay="100"
      >
        <div className="text-lg sm:text-xl text-textColor px-4 sm:px-7 sm:pb-5 py-5 mt-36 sm:mt-48">
          <p className="text-2xl sm:text-4xl font-bold mb-3">
            Limited Period Offer!
          </p>
          <p className="text-3xl sm:text-4xl font-bold my-4">50% OFF</p>
          <p className="sm:px-16">
            Get 50% off today on premium subscription
            <p className="font-bold">Use coupon code: EARLY50</p>
          </p>
        </div>
        <div
          className="flex justify-center bg-btn2 px-4 py-3 cursor-pointer mx-auto mb-5 sm:mb-8 xl:w-[20vw] sm:w-[35vw] w-[80vw] rounded-xl hover:bg-primary"
          onClick={handleCopyCoupon}
        >
          {copied ? (
            <>
              <p className="text-secondary font-bold sm:text-lg">COPIED...</p>
              <img src={TickIcon} alt="tick" className="ml-1 w-6 h-6" />
            </>
          ) : (
            <>
              <p className="text-secondary font-bold sm:text-lg">
                COPY CODE - EARLY50
              </p>
              <img src={CopyIcon} alt="copy" className="ml-4 w-6 h-6" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Coupon;
