import React, { useState, useEffect, useRef } from "react";
import ReactGA from "react-ga4";
import { useParams, Link } from "react-router-dom";
import data from "../utils/data.json";
import Header from "../components/Header";
import ColorPalette from "../components/ColorPalette";
import ColorCanvasSidebar from "../components/ColorCanvasSidebar";
import KidsAlsoLike from "../components/KidsAlsoLike";
import Footer from "../components/Footer";
import Similar from "../components/Similar";
import preview from "../assets/view.png";
import undo from "../assets/undo.png";
import reset from "../assets/reset.png";
import color from "../assets/canvas.png";
import download from "../assets/downloads.png";
import backIcon from "../assets/backbutton.png";
import { goBack } from "../utils/backFunction";
import { popularColors } from "../utils/popularColors";
import AOS from "aos";
import "aos/dist/aos.css";
import PremiumAd from "../components/PremiumAd";
import { Helmet } from "react-helmet";
import Popular from "../components/Popular";
import filterDrawings from "../utils/searchData";
import { fill } from "../utils/FloodFill";

function ColorCanvas() {
  // check if the user is logged in or not
  const isLoggedIn = localStorage.getItem("cookieFallback");

  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const { category, title } = useParams();

  // remove the hyphen from the title
  const formattedTitle = title.replace(/-/g, " ");

  // get the steps and images for the selected drawing
  const itemsArray = data.drawings.find(
    (item) => item.category === category
  ).items;

  let steps = [];
  itemsArray.forEach((item) => {
    if (item.title === formattedTitle) {
      steps = item.steps;
    }
  });

  let images = [];
  steps.forEach((stepItem) => {
    images.push(stepItem.imageUrl);
  });
  let seoData = {};
  itemsArray.forEach((item) => {
    if (item.title === formattedTitle) {
      seoData = item.colorcanvasseo;
    }
  });

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  //Preview Card Track for Mobile
  const [isPreviewVisible, setPreviewVisibility] = useState(false);

  //Color Palette Card Track for Mobile
  const [isPaletteVisible, setPaletteVisibility] = useState(false);

  const [currentColor, setCurrentColor] = useState("#FFFFFF");
  const canvasRef = useRef(null);
  const canvasImageRef = useRef(null);
  const history = useRef([]);
  const historyIndex = useRef(-1);

  const saveCanvasState = (canvasData) => {
    if (historyIndex.current < history.current.length - 1) {
      history.current = history.current.slice(0, historyIndex.current + 1);
    }
    history.current.push(canvasData);
    historyIndex.current = history.current.length - 1;
  };

  // Function to undo action
  const handleUndo = () => {
    if (historyIndex.current > 0) {
      historyIndex.current--;
      const canvasData = history.current[historyIndex.current];
      const ctx = canvasRef.current.getContext("2d");
      ctx.putImageData(canvasData, 0, 0);
    }
  };

  // Reset Button Function
  const resetCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Load the initial image (you may need to adjust the index)
    const initialImage = new Image();
    initialImage.src = images[images.length - 2];

    initialImage.onload = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(initialImage, 0, 0, canvas.width, canvas.height);
      canvasImageRef.current = ctx.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );
      // Save the canvas state after the reset
      saveCanvasState(ctx.getImageData(0, 0, canvas.width, canvas.height));
    };
  };

  // Function to download canvas image
  const handleDownloadClick = () => {
    const canvas = document.querySelector("canvas");
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = "canvas_image.png";
    link.click();
  };

  function hexToRgba(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b, 255];
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.src = images[images.length - 2];
    img.onload = () => {
      if (canvasImageRef.current) {
        ctx.putImageData(canvasImageRef.current, 0, 0);
      } else {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      }
    };
    const canvasClick = (e) => {
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvasRef.current.width / rect.width;
      const scaleY = canvasRef.current.height / rect.height;
      const x = Math.round((e.clientX - rect.left) * scaleX);
      const y = Math.round((e.clientY - rect.top) * scaleY);
      const pixel = ctx.getImageData(x, y, 1, 1).data;
      const targetColor = [pixel[0], pixel[1], pixel[2], pixel[3]];
      const fillColorArray = hexToRgba(currentColor);
      fill(ctx, canvas, x, y, targetColor, fillColorArray);
    };
    canvas.addEventListener("click", canvasClick);
    return () => {
      canvas.removeEventListener("click", canvasClick);
    };
    // eslint-disable-next-line
  }, [currentColor, steps, fill]);

  // Get the window width to determine if it's a mobile screen
  const isMobile = window.innerWidth <= 768;

  // Set different width and height based on whether it's a mobile screen or not
  const canvasWidth = isMobile ? window.innerWidth * 0.8 : 500;
  const canvasHeight = isMobile ? window.innerWidth * 0.8 : 500;

  // Search Query
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filterDrawings(searchQuery);

  // empty the search query when navigating to other drawings
  useEffect(() => {
    setSearchQuery("");
  }, [formattedTitle]);

  return (
    <>
      <Helmet>
        <title>{seoData.seotitle}</title>
        <meta name="description" content={seoData.seometadescription} />
        <meta name="keywords" content={seoData.seokeywords.join(", ")} />
        <h1>{seoData.seoh1}</h1>
        <h2>{seoData.seoh2.join(", ")}</h2>
        <body>{seoData.seobody}</body>
      </Helmet>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              🖌️ Pure Creativity, No Ads! Enjoy an uninterrupted color canvas
              and exclusive resources with Premium.
            </span>
            <span className="sm:hidden">🖌️ Pure Creativity, No Ads!</span>
          </>
        }
        cta="Paint with Premium!"
      />
      {isPreviewVisible && (
        <div className="fixed top-0 left-0 w-full h-full backdrop-blur-lg" />
      )}
      <Header
        category={category}
        setSearchQuery={setSearchQuery}
        searchQuery={searchQuery}
        showSearchBar={true}
      />
      {searchQuery && (
        <div className="mx-12 mb-12 mt-12">
          <h1 className="lg:text-2xl sm:text-xl font-bold text-textColor">
            Search Results
          </h1>
          {filteredData.length === 0 ? (
            <>
              <div className="flex justify-center my-14">
                <p className="text-xl text-textColor">
                  No results found for "{searchQuery}"
                </p>
              </div>
            </>
          ) : (
            <div className="mt-9 grid sm:grid-cols-4 grid-cols-2 sm:gap-7 gap-3">
              {filteredData.map((drawing) => (
                <Popular key={drawing.title} item={drawing} word="color" />
              ))}
            </div>
          )}
        </div>
      )}
      <div className="px-5 pt-7 bg-secondary sm:bg-opacity-0 bg-opacity-50 sm:hidden">
        <button onClick={goBack}>
          <img src={backIcon} alt="back" className="w-7" />
        </button>
      </div>
      <div className="sm:flex sm:justify-between bg-secondary sm:bg-opacity-0 bg-opacity-50 pt-[1px] lg:mt-4 sm:mb-2 px-5 sm:px-9">
        <button onClick={goBack} className="lg:mt-4 mt-6 sm:block hidden">
          <img src={backIcon} alt="back" className="lg:w-9 w-7" />
        </button>
        <h1 className="text-center lg:text-3xl sm:text-lg text-md sm:mt-6 mt-5 font-bold text-textColor">
          {seoData.seotitle}
        </h1>
        <div></div>
      </div>
      <div
        className="flex sm:flex-row flex-col gap-6 sm:gap-4 lg:gap-6 sm:p-6 lg:mt-4 sm:mt-2 mt-[-1.5rem] lg:mb-5 sm:mb-2 items-center sm:items-stretch"
        data-aos="fade-up"
      >
        <div>
          <ColorPalette
            setCurrentColor={setCurrentColor}
            currentColor={currentColor}
            isPaletteVisible={isPaletteVisible}
            setPaletteVisibility={setPaletteVisibility}
          />
        </div>
        <div className="sm:w-[80vw] w-[100vw] bg-secondary rounded-xl px-16 py-7 flex justify-center sm:bg-opacity-100 bg-opacity-50">
          <div>
            <div className="flex justify-center">
              <canvas
                ref={canvasRef}
                width={canvasWidth}
                height={canvasHeight}
                className="cursor-pointer rounded-xl sm:w-[28vw] w-[90vw]"
              ></canvas>
            </div>
            <div className="sm:mt-16 hidden mt-6 mb-5 sm:flex justify-center space-x-5">
              <button
                type="button"
                className="bg-btn2 text-secondary lg:px-3 px-2 lg:py-2 py-1 rounded-xl lg:text-xl text-md sm:w-[10vw] w-[20vw] hover-bg-btn1 hover:bg-textColor"
                onClick={handleUndo}
              >
                Undo
              </button>
              <button
                type="button"
                className="bg-btn3 text-secondary lg:px-3 px-2 lg:py-2 py-1 rounded-xl lg:text-xl text-md sm:w-[10vw] w-[20vw] hover-bg-btn1 hover:bg-textColor"
                onClick={resetCanvas}
              >
                Reset
              </button>
              {isLoggedIn ? (
                <button
                  type="button"
                  className="bg-primary text-secondary lg:px-3 px-2 lg:py-2 py-1 rounded-xl lg:text-xl text-md sm:w-[10vw] w-[20vw] hover-bg-btn1 hover:opacity-80"
                  onClick={handleDownloadClick}
                >
                  Download
                </button>
              ) : (
                <Link to="/login">
                  <button
                    type="button"
                    className="bg-primary text-secondary lg:px-3 px-2 lg:py-2 py-1 rounded-xl lg:text-xl text-md sm:w-[10vw] w-[20vw] hover-bg-btn1 hover:opacity-80"
                  >
                    Download
                  </button>
                </Link>
              )}
            </div>
            {/* For Mobile */}
            <div className="sm:mt-16 sm:hidden mt-6 mb-5 flex justify-center items-center space-x-10">
              <img
                src={preview}
                alt="preview"
                className="w-8 h-8"
                onClick={() => setPreviewVisibility(true)}
              />
              <img
                src={undo}
                alt="undo"
                className="w-6 h-6"
                onClick={handleUndo}
              />
              <img
                src={reset}
                alt="reset"
                className="w-6 h-6"
                onClick={resetCanvas}
              />
              {isLoggedIn ? (
                <img
                  src={download}
                  alt="download"
                  className="w-6 h-6"
                  onClick={handleDownloadClick}
                />
              ) : (
                <Link to="/login">
                  <img src={download} alt="download" className="w-6 h-6" />
                </Link>
              )}
            </div>
            <img
              src={color}
              alt="color"
              className="h-8 w-8 mx-auto sm:hidden"
              onClick={() => setPaletteVisibility(true)}
            />

            <div className="flex sm:hidden mt-4 justify-center space-x-10">
              {popularColors.slice(0, 4).map((colorInfo, index) => (
                <div
                  key={index}
                  style={{ backgroundColor: colorInfo.color }}
                  className="p-4 rounded-lg cursor-pointer border-2 border-[#111]"
                  onClick={() => setCurrentColor(colorInfo.color)}
                ></div>
              ))}
            </div>
          </div>
        </div>
        <div className="sm:hidden">
          <Link to={`/draw/${category}/${title}`}>
            <button className="bg-btn2 text-secondary px-3 py-2 rounded-lg hover:opacity-80 sm:mb-5 mb-2 sm:text-xl text-md">
              Open Drawing Canvas
            </button>
          </Link>
        </div>
        <div>
          <ColorCanvasSidebar
            category={category}
            title={title}
            image={images[images.length - 1]}
            isPreviewVisible={isPreviewVisible}
            setPreviewVisibility={setPreviewVisibility}
          />
        </div>
      </div>
      <Similar />
      <KidsAlsoLike />
      <Footer />
    </>
  );
}

export default ColorCanvas;