import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { handlePasswordUpdate } from "../utils/authUtils";

const Settings = () => {
  const navigate = useNavigate();

  // page view analytics
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  // States to manage password
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Function to update password
  const updatePassword = async (e) => {
    e.preventDefault();
    await handlePasswordUpdate(
      currentPassword,
      newPassword,
      confirmNewPassword,
      setError,
      setLoading,
      setSuccess,
      navigate
    );
  };

  return (
    <div className="bg-secondary sm:w-[80vw] w-[90vw] mx-auto rounded-xl sm:px-12 p-5 sm:py-7 mb-16 sm:mt-0 mt-6">
      <div className="text-center text-textColor">
        <h1 className="sm:mt-3 mt-1 sm:text-3xl text-md font-bold">
          Manage Your Password
        </h1>
        <p className="sm:mt-3 mt-1 sm:text-lg text-sm">
          To update your password, please enter your existing password.
        </p>
      </div>
      <div className="sm:mt-12 mt-6 sm:w-[30vw] w-full mx-auto">
        <form className="sm:w-[25vw] mx-auto" onSubmit={updatePassword}>
          <div>
            <label className="text-textColor sm:text-lg text-sm">
              Current Password
            </label>
            <input
              required
              type="password"
              placeholder="Enter Your Current Password"
              className="sm:px-4 sm:py-2 p-2 rounded-lg border-2 border-textColor sm:text-md text-sm sm:mt-2 mt-1 sm:w-[25vw] w-full"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
          <div className="sm:mt-5 mt-3">
            <label className="text-textColor sm:text-lg text-sm">
              New Password
            </label>
            <input
              required
              type="password"
              placeholder="Enter Your New Password"
              className="sm:px-4 sm:py-2 p-2 rounded-lg border-2 border-textColor sm:text-md text-sm sm:mt-2 mt-1 sm:w-[25vw] w-full"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="sm:mt-5 mt-3">
            <label className="text-textColor sm:text-lg text-sm">
              Confirm New Password
            </label>
            <input
              required
              type="password"
              placeholder="Re-enter Your New Password"
              className="sm:px-4 sm:py-2 p-2 rounded-lg border-2 border-textColor text-md sm:mt-2 mt-1 sm:w-[25vw] w-full"
              onChange={(e) => {
                setConfirmNewPassword(e.target.value);
              }}
            />
          </div>
          <div className="sm:mt-8 mt-5">
            <button
              type="submit"
              className={`sm:w-[25vw] w-full bg-btn2 text-secondary px-3 py-2 sm:text-lg text-md rounded-xl hover:bg-primary ${
                loading ? "opacity-80" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Loading..." : "Update Your Password"}{" "}
            </button>
          </div>
          <div className="sm:mt-5 mt-2">
            {error && (
              <p className="sm:text-lg text-md text-[#ff0101] font-bold text-center">
                {error}
              </p>
            )}
            {success && (
              <p className="sm:text-lg text-md text-btn2 font-bold text-center">
                Password updated successfully. Redirecting to login page...
              </p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
