import data from "./data.json";

const filterDrawings = (searchQuery) => {
  const filteredData = data.drawings.filter((drawing) => {
    const lowercaseSearchQuery = searchQuery.toLowerCase();
    return drawing.items.some((item) => {
      return (
        item.title.toLowerCase().includes(lowercaseSearchQuery) ||
        (item.seo &&
          item.seo.seowords &&
          item.seo.seowords.some((keyword) =>
            keyword.toLowerCase().includes(lowercaseSearchQuery)
          ))
      );
    });
  });

  const filteredDrawings = filteredData.flatMap((drawing) => {
    return drawing.items.filter((item) => {
      const lowercaseSearchQuery = searchQuery.toLowerCase();
      return (
        item.title.toLowerCase().includes(lowercaseSearchQuery) ||
        (item.seo &&
          item.seo.seowords &&
          item.seo.seowords.some((keyword) =>
            keyword.toLowerCase().includes(lowercaseSearchQuery)
          ))
      );
    });
  });

  return filteredDrawings;
};

export default filterDrawings;
