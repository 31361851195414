import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import SubCategory from "./SubCategory";

const SubDraw = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }, []);

  const url = window.location.href;
  const urlParts = url.split("/");
  const drawWord = urlParts[urlParts.length - 1];
  return (
    <div>
      <SubCategory word={drawWord} />
    </div>
  );
};

export default SubDraw;
