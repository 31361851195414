import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Link, useNavigate } from "react-router-dom";
import { loginUser, googleAuth } from "../utils/authUtils";
import googleIcon from "../assets/googleIcon.png";

const Login = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // Navigate
  const navigate = useNavigate();

  // Login States
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Function to handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    loginUser(user.email, user.password, setLoading, setError, navigate);
  };

  // Function to handle google auth
  const handleGoogleAuth = (e) => {
    e.preventDefault();
    googleAuth();
  };

  return (
    <>
      <div className="sm:mt-12 mt-8">
        <div className="mt-12 mb-9">
          <h1 className="text-center font-bold sm:text-4xl text-xl text-textColor">
            Welcome, Young Artist!
          </h1>
          <p className="text-center text-textColor sm:mt-2 mt-1 sm:text-lg text-sm sm:px-0 px-5">
            Login to Get Started with DrawInkPaper
          </p>
        </div>
        <div className="bg-secondary mt-7 mx-auto rounded-2xl sm:px-9 px-7 sm:py-7 py-4 lg:w-[27vw] md:w-[40vw] w-[90vw]">
          <h1 className="sm:text-2xl text-xl font-bold text-textColor text-center sm:mb-9 mb-5">
            Log In
          </h1>
          <form onSubmit={handleLogin}>
            <div className="sm:mb-4 mb-3">
              <label className="text-textColor sm:text-base text-sm">
                Email Address
              </label>
              <input
                required
                type="text"
                placeholder="Enter your Email Address"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md sm:mt-2 mt-1"
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label className="text-textColor sm:text-base text-sm">
                Password
              </label>
              <input
                required
                type="password"
                placeholder="Enter your Password"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md sm:mt-2 mt-1"
                onChange={(e) => {
                  setUser({
                    ...user,
                    password: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mt-3">
              <Link to="/forgot-password">
                <p className="cursor-pointer hover:text-primary text-sm text-right">
                  Forgot Password
                </p>
              </Link>
            </div>
            <div className="sm:mt-5 mt-3">
              <button
                type="submit"
                className={`text-center w-full bg-btn1 text-secondary p-2 rounded-lg sm:text-lg text-md hover:bg-primary ${
                  loading ? "opacity-80" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Loading..." : "Login"}{" "}
              </button>
            </div>
            <div className="sm:mt-2 mt-5 sm:text-base text-sm">
              <p className="text-center text-textColor cursor-pointer">
                Don't have an account?{" "}
                <Link to="/signup">
                  <span className="font-bold hover:text-primary">Signup</span>
                </Link>
              </p>
            </div>
            <div className="sm:mt-3 mt-1 text-center sm:text-lg text-sm text-textColor">
              <span>Or Login With</span>
            </div>
            <div className="sm:mt-5 mt-3 flex justify-center space-x-5">
              <p className="cursor-pointer" onClick={handleGoogleAuth}>
                <img src={googleIcon} alt="googleicon" className="sm:w-9 w-7" />
              </p>
            </div>
            {error && (
              <div className="sm:mt-5 mt-4 sm:text-lg text-sm text-[#ff0101] font-bold text-center">
                <p>{error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
