import { Link } from "react-router-dom";
import Popular from "./Popular";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode, Autoplay, Navigation } from "swiper/modules";
import trendingDrawings from "../utils/trendingDrawings";

const KidsAlsoLike = () => {
  // get path name
  const pathName = window.location.pathname;
  const pathNameArray = pathName.split("/");
  const word = pathNameArray[1];
  const currentDrawing = pathNameArray[3];

  return (
    <div className="sm:px-12 sm:pb-12 px-5">
      <div className="flex justify-between">
        <h1 className="lg:text-3xl sm:text-2xl text-lg font-bold text-textColor">
          Artists Also Like
        </h1>
        <div>
          <Link to="/populardrawings/all">
            <button className="bg-btn1 text-secondary lg:px-3 px-2 lg:py-2 py-1 sm:text-md text-sm rounded-lg hover:opacity-80">
              View More
            </button>
          </Link>
        </div>
      </div>
      <div className="sm:mt-5 mt-3 sm:mb-12 mb-20">
        <Swiper
          breakpoints={{
            340: {
              slidesPerView: 2,
              spaceBetween: 15,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 20,
            },
          }}
          freeMode={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          navigation
          loop={true}
          modules={[FreeMode, Autoplay, Navigation]}
        >
          {trendingDrawings.map((item) => {
            if (item.title !== currentDrawing) {
              return (
                <SwiperSlide key={item.title}>
                  <Popular key={item.title} item={item} word={word} />
                </SwiperSlide>
              );
            } else {
              return null;
            }
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default KidsAlsoLike;
