import { useState, useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ID } from "appwrite";
import { databases } from "../appwrite/appwriteConfig";

const DrawingTutorialModal = ({ isOpen, closeModal }) => {
  // Initialize AOS
  useEffect(() => {
    AOS.init();
  }, []);

  // State variables
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // Appwrite database and collection IDs
  const databaseId = process.env.REACT_APP_APPWRITE_DATABASE_ID;
  const drawingTutorialCollectionId =
    process.env.REACT_APP_USER_DRAWING_TUTORIAL_REQUEST_COLLECTION_ID;

  // Function to submit request
  const submitRequest = async (e) => {
    e.preventDefault();
    try {
      await databases.createDocument(
        databaseId,
        drawingTutorialCollectionId,
        ID.unique(),
        {
          message: message,
          email: userEmail,
        }
      );
      setIsRequestSent(true);
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  };

  // Close modal when clicked outside
  const ref = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, closeModal]);

  return (
    <>
      {isOpen ? (
        <>
          <div
            className="fixed inset-0 z-10 overflow-y-auto"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div
                ref={ref}
                className="relative w-full max-w-lg mx-auto bg-secondary rounded-xl shadow-lg p-9"
              >
                {isRequestSent ? (
                  <>
                    <h1 className="text-center text-xl font-bold text-textColor">
                      Thank you for submitting your request!
                    </h1>
                    <p className="text-center mt-3 font-bold text-textColor text-lg">
                      Our team reads each request carefully and will reach out
                      to you for future updates.
                    </p>
                  </>
                ) : (
                  <>
                    <h1 className="text-center text-xl font-bold text-textColor">
                      Ask for a drawing tutorial and we will do our best to
                      create one for you!
                    </h1>
                    <div className="flex justify-end mt-1">
                      <span className="bg-textColor text-secondary px-2 rounded-md">
                        Tutorial Request
                      </span>
                    </div>
                    <form className="mt-3" onSubmit={submitRequest}>
                      <div className="mb-2">
                        <label className="font-bold text-textColor">
                          What would you like to draw?
                        </label>
                        <textarea
                          required
                          name="message"
                          cols="30"
                          rows="3"
                          placeholder="For example: Spongebob"
                          className="w-full mt-1 px-3 py-2 rounded-md border-[1px]"
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="mb-7">
                        <label className="font-bold text-textColor">
                          Your Email Address (We will notify you once the
                          requested drawing is available)
                        </label>
                        <input
                          required
                          type="email"
                          placeholder="Enter your Email"
                          className="w-full mt-1 px-3 py-2 rounded-md border-[1px]"
                          name="user_email"
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="flex space-x-3">
                        <button
                          type="submit"
                          className="w-full bg-primary text-secondary text-lg px-3 py-2 rounded-lg hover:opacity-80"
                        >
                          Send Request
                        </button>
                        <button
                          type="submit"
                          className="w-full bg-btn1 text-secondary text-lg px-3 py-2 rounded-lg hover:opacity-80"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                      {error && (
                        <p className="text-center font-bold text-[#ff0101] text-lg mt-7">
                          There was an error in sending your request. Please try
                          again later.
                        </p>
                      )}
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DrawingTutorialModal;
