import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../utils/authUtils";

const ResetPassword = () => {
  // use navigate hook from react-router-dom
  const navigate = useNavigate();

  // State for password reset
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordAgain, setNewPasswordAgain] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");

  // Function to handle password reset
  const handleResetPassword = async (e) => {
    e.preventDefault();
    resetPassword(
      newPassword,
      newPasswordAgain,
      setLoading,
      setError,
      setAlert,
      navigate
    );
  };

  return (
    <>
      <div className="sm:mt-28 mt-14">
        <div className="bg-secondary sm:mt-7 mt-3 mx-auto rounded-2xl sm:p-9 p-5 sm:w-[27vw] w-[80vw]">
          <h1 className="sm:text-2xl text-xl font-bold text-textColor text-center sm:mb-9 mb-6">
            Reset Password
          </h1>
          <form onSubmit={handleResetPassword}>
            <div className="sm:mb-4 mb-2">
              <label className="text-textColor">Enter your New Password</label>
              <input
                required
                type="password"
                placeholder="Enter your New Password"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md mt-2"
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="sm:mb-4 mb-2">
              <label className="text-textColor">
                Enter your New Password Again
              </label>
              <input
                required
                type="password"
                placeholder="Enter your New Password Again"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor sm:text-md text-sm mt-2"
                onChange={(e) => setNewPasswordAgain(e.target.value)}
              />
            </div>
            <div className="sm:mt-7 mt-3">
              <button
                type="submit"
                className={`text-center w-full bg-btn1 text-secondary p-2 rounded-lg sm:text-lg text-md hover:bg-primary ${
                  loading ? "opacity-80" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Loading..." : "Reset Password"}{" "}
              </button>
            </div>
            {error && (
              <div className="sm:mt-5 mt-2 sm:text-lg text-md text-[#ff0101] font-bold text-center">
                <p>{error}</p>
              </div>
            )}
            {alert && (
              <div className="sm:mt-9 mt-5 sm:text-lg text-md font-bold text-center">
                <p>{alert}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
