import React from "react";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const SubHeader = ({
  category,
  categoryDescription,
  setSidebarVisibility,
  isCategoryBtnVisible,
  setSearchQuery,
  showSearchBar,
}) => {
  // Get the current path
  const path = window.location.pathname;

  return (
    <>
      {/* For Mobile */}
      <div className="flex justify-between mt-5 mb-7 sm:mt-0 sm:mb-0">
        {showSearchBar && ( // Conditionally render search bar
          <div className="sm:hidden">
            <input
              type="text"
              placeholder="Search for Drawings"
              className={`border-[1px] border-textColor px-3 py-2 rounded-lg w-[60vw] text-sm ${
                isCategoryBtnVisible ? "" : "w-[87vw]"
              }`}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        )}
        <button
          className={`bg-btn2 text-secondary sm:hidden sm:px-3 px-2 sm:py-2 py-1 rounded-lg hover:opacity-80 text-sm w-[25vw] ${
            isCategoryBtnVisible ? "" : "hidden"
          }`}
          onClick={() => setSidebarVisibility(true)}
        >
          Category
        </button>
      </div>
      <div className="sm:mt-0 mt-5">
        {category && categoryDescription ? (
          <div className="flex space-x-5">
            <button onClick={goBack} className="sm:mb-9 mb-2 mt-2">
              <img src={backIcon} alt="back" className="sm:w-10 w-9" />
            </button>
            <div className="text-center sm:text-left">
              <h1 className="lg:text-3xl sm:text-2xl text-xl font-bold text-textColor sm:mt-auto mt-1">
                {category}
              </h1>
              <p className="lg:text-xl sm:text-lg text-textColor">
                {categoryDescription}
              </p>
            </div>
          </div>
        ) : (
          <>
            <div>
              {(path === "/draw" || path === "/") && (
                <>
                  <h1 className="lg:text-[1.7rem] sm:text-2xl sm:mt-2 sm:text-left text-lg font-bold text-textColor text-center mt-3 mb-4">
                    Grab your crayons, and learn to draw with easy Step by Step Drawing Tutorials
                  </h1>
                  <p className="lg:text-xl sm:text-lg text-sm text-textColor sm:text-left text-center">
                    Learn how to draw anything in fun and easy step by step
                    guide of images with instructions. Pick anything you wish to
                    draw from a simple cartoon character to cars to humans, you
                    will find it all here.
                  </p>
                </>
              )}
              {path === "/color" && (
                <>
                  <h1 className="lg:text-3xl sm:text-2xl text-lg font-bold text-textColor mt-2">
                    Start Coloring!
                  </h1>
                  <p className="lg:text-xl sm:text-lg text-sm text-textColor">
                    Ready to have fun and learn how to color cool stuff?
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SubHeader;