import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Home from "./Home";

const Color = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const url = window.location.href;
  const urlParts = url.split("/");
  const colorWord = urlParts[urlParts.length - 1];

  return <Home word={colorWord} />;
};

export default Color;
