import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { storage } from "../appwrite/appwriteConfig";
import useUserData from "../utils/useUserData";

const Modal = ({ showModal, category, title, onClose }) => {
  // check if the user is logged in or not
  const isLoggedIn = localStorage.getItem("cookieFallback");

  // Get User Id
  const userId = useUserData().id;

  // Get Bucket Id
  const bucketId = process.env.REACT_APP_APPWRITE_STORAGE_BUCKET_ID;

  // States for Image Upload
  const [selectedImage, setSelectedImage] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [error, setError] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  // Get File Name
  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file.name);
    }
  };

  // Upload image to appwrite storage bucket
  let filename;
  const uploadImageToAppwrite = async (file, userId, category, title) => {
    try {
      filename = `${category}-${title}-${userId}`;
      await storage.createFile(
        bucketId,
        filename,
        document.getElementById("drawing_input").files[0]
      );
      setUploadStatus(true);
    } catch (error) {
      setUploadStatus(false);
      setError(true);
    }
  };

  // Function to Upload Image
  const handleUploadImage = async () => {
    if (selectedImage) {
      try {
        await uploadImageToAppwrite(
          document.getElementById("drawing_input").files[0],
          userId,
          category,
          title
        );
        setUploadStatus(true);
        setIsUploaded(true);
      } catch (error) {
        setError(true);
      }
    }
  };

  // Function to close the modal when clicking outside
  const handleCloseModalOutside = useCallback(
    (e) => {
      if (e.target.classList.contains("modal-backdrop")) {
        onClose();
      }
    },
    [onClose]
  );

  // Listen for clicks outside the modal and close it
  useEffect(() => {
    if (showModal) {
      window.addEventListener("click", handleCloseModalOutside);
    }
    return () => {
      window.removeEventListener("click", handleCloseModalOutside);
    };
  }, [showModal, handleCloseModalOutside]);

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center sm:items-center flex right-10 lg:right-0 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none modal-backdrop">
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl sm:bg-secondary rounded-xl modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="rounded-xl shadow-xl relative flex flex-col sm:w-[30vw] w-[95vw] sm:items-center bg-secondary">
                {isLoggedIn ? (
                  <>
                    <h1 className="text-center lg:text-xl text-md mt-5 mb-2 text-textColor font-bold">
                      Upload Your Progress
                    </h1>
                    <div className="px-12 py-5">
                      <div className="flex justify-center">
                        <input
                          id="drawing_input"
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          onChange={handleImageInputChange}
                          className="cursor-pointer sm:ml-20 sm:text-base text-sm"
                        />
                      </div>
                      <div className="sm:mt-9 mt-7 flex space-x-2 justify-center">
                        {isUploaded && !error ? (
                          <Link to="/profile" state={{ from: "progress" }}>
                            <button className="bg-btn1 text-secondary sm:px-3 sm:py-2 px-2 py-1 sm:text-base text-sm rounded-xl hover:opacity-70">
                              View Progress
                            </button>
                          </Link>
                        ) : (
                          <button
                            className="bg-btn1 text-secondary sm:px-3 sm:py-2 px-2 py-1 sm:text-base text-sm sm:rounded-xl rounded-lg hover:opacity-70"
                            onClick={handleUploadImage}
                          >
                            Upload
                          </button>
                        )}
                        <button
                          className="bg-textColor text-secondary sm:px-3 sm:py-2 px-2 py-1 sm:rounded-xl rounded-lg hover:opacity-70"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                    <div>
                      {uploadStatus && !error && (
                        <div className="text-center sm:mb-7 mb-5 sm:text-base text-sm">
                          <p>Drawing Uploaded </p>
                        </div>
                      )}
                      {error && (
                        <div className="text-center mb-7 mt-2">
                          <p className="text-[#ff0101] sm:px-0 px-12 sm:text-base text-sm">
                            You have already uploaded this drawing
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="lg:px-12 px-6 lg:py-9 py-3 bg-secondary rounded-xl text-textColor shadow-xl">
                    <p className="text-center lg:text-2xl text-md">
                      Login To Save Your Progress and Earn Rewards
                    </p>
                    <div className="flex space-x-2 justify-center sm:mt-7 mt-5">
                      <Link to="/login">
                        <button className="bg-primary text-secondary lg:px-3 lg:py-2 px-2 py-1 rounded-md sm:rounded-xl sm:text-base text-sm">
                          Log In
                        </button>
                      </Link>
                      <button
                        className="bg-btn1 text-secondary lg:px-3 lg:py-2 px-2 py-1 sm:rounded-xl sm:text-base text-sm rounded-md"
                        onClick={onClose}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
