import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgetPassword } from "../utils/authUtils";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [alert, setAlert] = useState("");

  // use navigate hook from react-router-dom
  const navigate = useNavigate();

  // Function to handle password recovery
  const handlePasswordRecovery = async (e) => {
    e.preventDefault();
    await forgetPassword(email, setLoading, setError, setAlert, navigate);
  };

  return (
    <>
      <div className="sm:mt-20 mt-10">
        <div className="bg-secondary sm:mt-7 mt-4 mx-auto rounded-2xl p-9 sm:w-[27vw] w-[80vw]">
          <h1 className="sm:text-2xl text-xl font-bold text-textColor text-center sm:mb-9 mb-4">
            Forgot Password
          </h1>
          <form onSubmit={handlePasswordRecovery}>
            <div className="sm:mb-4 mb-2">
              <label className="text-textColor">
                Enter your Email Address for Password Recovery
              </label>
              <input
                required
                type="text"
                placeholder="Enter your Email Address"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md mt-2"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="sm:mt-7 mt-4">
              <button
                type="submit"
                className={`text-center w-full bg-btn1 text-secondary p-2 rounded-lg sm:text-lg text-md hover:bg-primary ${
                  loading ? "opacity-80" : ""
                }`}
                disabled={loading}
              >
                {loading ? "Loading..." : "Send Password Recovery Email"}{" "}
              </button>
            </div>
            {error && (
              <div className="sm:mt-5 mt-2 sm:text-lg text-md text-[#ff0101] font-bold text-center">
                <p>{error}</p>
              </div>
            )}
            {alert && (
              <div className="sm:mt-9 mt-5 sm:text-lg text-md font-bold text-center">
                <p>{alert}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
