// DrawOnline.jsx
import React from 'react';
import OnlineDrawingCanvas from "../components/OnlineDrawingCanvas";

const DrawOnline = () => {
    return (
        <div className="flex justify-center items-center h-screen">
            <OnlineDrawingCanvas />
        </div>
    );
};

export default DrawOnline;
