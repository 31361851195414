import { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { storage } from "../appwrite/appwriteConfig";
import useUserData from "../utils/useUserData";

const Progress = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const bucketId = process.env.REACT_APP_APPWRITE_STORAGE_BUCKET_ID;
  const userId = useUserData().id;
  const [urls, setUrls] = useState([]);

  const getFiles = async () => {
    try {
      const response = await storage.listFiles(bucketId);
      const userUploadedImageArray = response.files
        .filter((file) => {
          const parts = file.$id.split("-");
          return parts[2] === userId;
        })
        .map((file) => file.$id);
      const imageUrls = await Promise.all(
        userUploadedImageArray.map(async (id) => {
          const result = storage.getFilePreview(bucketId, id, 250);
          return result.href;
        })
      );
      setUrls(imageUrls);
    } catch (err) {
      console.log(err);
    }
  };

  // Get user progress images on page load
  useEffect(() => {
    getFiles();
  });

  return (
    <>
      <div className="bg-secondary sm:w-[80vw] w-[90vw] mx-auto rounded-xl sm:px-12 p-5 sm:py-5 mb-16 sm:mt-0 mt-6">
        <h1 className="text-center sm:mt-5 mt-2 sm:text-3xl text-md text-textColor font-bold">
          Save your hand made drawings and earn insane rewards and discounts
        </h1>
        <p className="sm:mt-3 mt-1 text-center text-textColor sm:text-xl text-sm">
          Upload First five drawings to earn 10% off on Premium Memberships -
          unlimited ad-free drawing tutorials and Free Coloring Printables! *You
          will receive an email with discount code within 24 hours.
        </p>
        {/* eslint-disable-next-line */}
        {urls.length == 0 ? (
          <div className="text-center sm:text-xl text-md sm:mt-24 mt-12">
            <p>No Progress Data</p>
          </div>
        ) : (
          <div className="grid sm:grid-cols-4 grid-cols-2 sm:gap-10 gap-2 sm:mt-16 mt-8 sm:mb-5 mb-2 sm:px-5 px-2">
            {urls.map((url, index) => (
              <div
                key={index}
                className="sm:w-[200px] sm:h-[200px] w-[100px] h-[100px]"
              >
                <img
                  src={url}
                  alt={`uploaded-drawing-${index}`}
                  className="rounded-xl border-[0.5px] border-textColor object-cover w-full h-full"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Progress;
