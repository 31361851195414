import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PremiumAd from "../components/PremiumAd";
import AOS from "aos";
import "aos/dist/aos.css";
import blogs from "../utils/blogs.json";
import seodata from "../utils/seodata.json";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const Blogs = () => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>{seodata.allblogspage.seotitle}</title>
        <meta
          name="description"
          content={seodata.allblogspage.seometadescription}
        />
        <meta
          name="keywords"
          content={seodata.allblogspage.seokeywords.join(", ")}
        />
        <h1>{seodata.allblogspage.seoh1}</h1>
        <h2>{seodata.allblogspage.seoh2.join(", ")}</h2>
        <body>{seodata.allblogspage.seobody}</body>
      </Helmet>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              📚 Read Art Blogs Uninterrupted! Go Premium for ad-free access to
              informative articles and free ebooks.
            </span>
            <span className="sm:hidden">📚 Read Art Blogs Uninterrupted!</span>
          </>
        }
        cta="Read with Premium!"
      />
      <Header />
      <div
        className="lg:pb-28 lg:pt-16 sm:pt-8 sm:pb-18 pt-5 pb-6"
        data-aos="fade-left"
      >
        <div className="text-center sm:mt-0 mt-9">
          <button
            onClick={goBack}
            className="absolute sm:left-14 left-5 sm:top-auto top-6"
          >
            <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
          </button>
          <h1 className="lg:text-4xl sm:text-3xl text-xl font-extrabold text-textColor">
            From the blog
          </h1>
          <h5 className="sm:mt-3 mt-1 lg:text-xl text-md sm:px-0 px-5 text-textColor">
            Learn more about DrawInkPaper and how we are helping people to be
            more creative.
          </h5>
        </div>
        <div className="mx-auto lg:mt-12 mt-6">
          {blogs.blogs.map((blog) => (
            <article
              key={blog.id}
              className="bg-secondary rounded-2xl mb-9 grid sm:grid-cols-3 grid-cols-1 sm:w-[60vw] w-[90vw] mx-auto"
            >
              <div className="col-span-1 mx-auto">
                <img
                  src={blog.image}
                  alt={`blog + ${blog.title}`}
                  className="rounded-2xl sm:w-auto w-[65vw]"
                />
              </div>
              <div className="col-span-2 sm:p-7 p-4">
                <h3 className="lg:text-2xl sm:text-xl font-semibold">
                  <Link
                    to={`/blog/${blog.title.replace(/\s+/g, "-")}`}
                    className="hover:text-btn1 text-textColor"
                  >
                    {blog.title}
                  </Link>
                </h3>
                <p className="sm:mt-3 mt-1 lg:text-lg sm:text-md sm:line-clamp-5 line-clamp-3">
                  {blog.description}
                </p>
                <Link to={`/blog/${blog.title.replace(/\s+/g, "-")}`}>
                  <h6 className="lg:text-lg text-md font-bold hover:text-btn1 cursor-pointer mt-3 text-textColor">
                    Read More
                  </h6>
                </Link>
              </div>
            </article>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Blogs;
