import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const TermsofUse = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <div
        className="flex justify-between lg:space-x-14 mt-12 lg:mb-20 mb-9 lg:px-20 px-5"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute sm:left-14 left-3 lg:top-auto sm:top-4 top-[-0.5rem]"
        >
          <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
        </button>

        <div className="lg:w-full w-[90vw] bg-secondary rounded-lg lg:px-5 px-2 lg:pt-11 pt-5 lg:pb-16 pb-8 mx-auto">
          <div className="text-center">
            <h2 className="sm:text-2xl text-lg sm:pt-0 pt-3 font-bold text-textColor">
              DrawInkPaper Terms of Use
            </h2>
            <p className="sm:text-lg text-base sm:mt-2 mt-1 text-textColor font-bold">
              Last Updated: Dec 2023
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="introduction">
            <h1 className="sm:text-2xl text-md">1) Introduction</h1>
            <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
              Welcome to DrawInkPaper, a digital learning platform for artists,
              operated by Alai Technologies ("Company", "we", "us", or "our").
              Our services are accessible via our website and mobile
              applications on Android and iOS platforms ("Service"). These Terms
              of Use govern your access to and use of our Service.
              <br />
              <span className="font-bold">
                Acceptance of Terms: <br></br>By accessing or using the Service,
                you agree to be bound by these Terms and our Privacy Policy. If
                you do not agree, do not use our Service.
              </span>
            </p>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="collect">
            <h1 className="sm:text-2xl text-md">2) Registration and Account</h1>
            <div className="mt-3 text-textColor">
              <h5 className="font-bold sm:text-xl text-md">
                Account Creation:
              </h5>
              <div className="sm:mt-3 mt-1 sm:text-lg text-sm">
                <div className="sm:mt-2 mt-1">
                  <p>
                    To access certain features of the Service, you must register
                    and provide accurate, current, and complete information.{" "}
                    <br></br>
                    Account Responsibility: You are responsible for all
                    activities that occur under your account and for keeping
                    your password confidential.
                  </p>
                </div>
              </div>
              <div className="mt-3 text-textColor">
                <h5 className="font-bold sm:text-xl text-md">
                  Account Responsibility:
                </h5>
                <div className="sm:mt-3 mt-1 sm:text-lg text-sm">
                  <div className="sm:mt-2 mt-1">
                    <p>
                      You are responsible for all activities that occur under
                      your account and for keeping your password confidential.
                    </p>
                  </div>
                  <div className="sm:mt-2 mt-1">
                    <p>
                      Cookies: Our platform uses cookies to improve user
                      experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="usage">
              <h1 className="sm:text-2xl text-md">
                3) Subscription and Payment
              </h1>
              <div className="sm:mt-5 mt-2 text-textColor">
                <p className="font-bold sm:text-xl text-md">Plans:</p>
                <div className="sm:mt-2 mt-1 text-sm">
                  <p>
                    The Service offers both free and premium subscription plans.
                    The free plan includes ads, while the premium plan offers
                    additional features and ad-free experience.
                  </p>
                </div>
              </div>
              <div className="mt-2 text-textColor">
                <p className="font-bold text-md">Payments for Mobile App:</p>
                <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                  <p>
                    For mobile app users, payments for premium subscriptions
                    will be processed through the respective app stores (Google
                    Play Store or Apple App Store) as per their terms and
                    conditions.
                  </p>
                </div>
              </div>
              <div className="mt-2 text-textColor">
                <p className="font-bold text-md">Payments for Web App:</p>
                <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                  <p>
                    For web app users, payments are processed via Stripe for Web
                    App, Google Play Store for Andriod app and Apple App Store
                    for iOS app. All Payment data is stored securely by the
                    respective payment processors.
                  </p>
                </div>
              </div>
              <div className="mt-2 text-textColor">
                <p className="font-bold sm:text-xl text-md">
                  Renewals and Cancellations:{" "}
                </p>
                <div className="sm:mt-2 mt-1 sm:text-lg text-sm">
                  <p>
                    Subscriptions are automatically renewed. Users can cancel
                    their subscription as per the guidelines provided in the app
                    stores or on our website.
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="sharing">
              <h1 className="sm:text-2xl text-md">
                4) Intellectual Property Rights
              </h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                Ownership: All materials within the Service, including text,
                graphics, and user interfaces, are the property of the Company
                or its licensors and are protected by intellectual property
                laws.
                <br></br>License to Use: We grant you a limited, non-exclusive,
                non-transferable license to access and use the Service for
                personal, non-commercial purposes.
              </p>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="security">
              <h1 className="sm:text-2xl text-md">5) User Conduct</h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                Acceptable Use: You agree not to use the Service for any
                unlawful purpose or in any way that interrupts, damages, or
                impairs the Service.<br></br>
                Content Restrictions: You must not upload, share, or otherwise
                distribute any content that is illegal, harmful, or offensive.
              </p>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="children">
              <h1 className="sm:text-2xl text-md">6) Privacy Policy</h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                DrawInkPaper is designed for children, and as such, we comply
                with applicable laws and regulations concerning children's
                privacy, Please check our privacy policy for more details.
              </p>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="choices">
              <h1 className="sm:text-2xl text-md">
                7) Modifications to the Service
              </h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                We reserve the right to modify or discontinue the Service or any
                part of it without prior notice.
              </p>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="updates">
              <h1 className="sm:text-2xl text-md">
                8) Disclaimer of Warranties
              </h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                The Service is provided "as is" and "as available" without any
                warranties of any kind, either express or implied, including but
                not limited to the implied warranties of merchantability,
                fitness for a particular purpose, or non-infringement.
              </p>
            </div>
            <div className="sm:mt-12 mt-5 sm:px-9 px-2" id="liability">
              <h1 className="sm:text-2xl text-md">
                9) Limitation of Liability
              </h1>
              <p className="sm:mt-3 mt-1 sm:text-lg text-sm text-textColor">
                To the fullest extent permitted by applicable law, in no event
                will the Company be liable for any indirect, incidental,
                special, consequential, or punitive damages arising out of or in
                connection with your use of the Service.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsofUse;
