import React, { useState, useEffect } from "react";
import SubCategory from "./pages/SubCategory";
import Home from "./pages/Home";
import { Link, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Draw from "./pages/Draw";
import Color from "./pages/Color";
import DrawCanvas from "./pages/DrawCanvas";
import ColorCanvas from "./pages/ColorCanvas";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import SubDraw from "./pages/SubDraw";
import SubColor from "./pages/SubColor";
import ReactGA from "react-ga4";
import ForgotPassword from "./pages/ForgotPassword";
import Privacy from "./pages/Privacy";
import PopularDrawings from "./pages/PopularDrawings";
import AllCategories from "./pages/AllCategories";
import CheckoutForm from "./components/CheckoutForm";
import Blogs from "./pages/Blogs";
import Blog from "./components/Blog";
import VerifyEmail from "./components/VerifyEmail";
import About from "./pages/About";
import Contact from "./pages/ContactUs";
import RefundPolicy from "./pages/RefundPolicy";
import Confirmation from "./pages/Confirmation";
import ResetPassword from "./pages/ResetPassword";
import ScrollToTop from "./components/ScrollToTop";
import DrawOnline from "./pages/DrawOnline";
import DeleteYourData from "./pages/DeleteYourData";
import PremiumDrawings from "./pages/PremiumDrawings";
import SimilarDrawings from "./pages/SimilarDrawings";
import GenerateDrawingAI from "./pages/GenerateDrawingAI";
import TermsofUse from "./pages/TermsofUse";
import AOS from "aos";
import "aos/dist/aos.css";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);

const App = () => {
  // check if user is logged in
  const isLoggedIn = localStorage.getItem("cookieFallback");

  // state for showing sign up modal
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setShowSignUpModal(true);
      }, 8 * 60 * 1000);
    };
    resetTimer();
    resetTimer();
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/draw" element={<Draw />} />
          <Route path="/color" element={<Color />} />
          <Route path="/subcategory/:category" element={<SubCategory />} />
          <Route path="/populardrawings/all" element={<PopularDrawings />} />
          <Route
            path="/populardrawings/all/draw"
            element={<PopularDrawings />}
          />
          <Route
            path="/populardrawings/all/color"
            element={<PopularDrawings />}
          />
          <Route path="/allcategories" element={<AllCategories />} />
          <Route path="/allcategories/draw" element={<AllCategories />} />
          <Route path="/allcategories/color" element={<AllCategories />} />
          <Route path="/draw/:category/:title" element={<DrawCanvas />} />
          <Route path="/color/:category/:title" element={<ColorCanvas />} />
          <Route path="/subcategory/:category/draw" element={<SubDraw />} />
          <Route path="/privacypolicy" element={<Privacy />} />
          <Route path="/subcategory/:category/color" element={<SubColor />} />
          <Route path="/checkout" element={<CheckoutForm />} />
          <Route path="/VerifyEmail" element={<VerifyEmail />} />
          <Route path="/allblogs" element={<Blogs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:title" element={<Blog />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/confirmation" element={<Confirmation />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/drawonline" element={<DrawOnline />} />
          <Route path="/deleteyourdata" element={<DeleteYourData />} />
          <Route path="/premium-drawings" element={<PremiumDrawings />} />
          <Route path="/generate-ai-drawing" element={<GenerateDrawingAI />} />
          <Route path="/termsofuse" element={<TermsofUse />} />
          <Route
            path="/similar-drawings/:currentCategory/:currentDrawing"
            element={<SimilarDrawings />}
          />
          {/* accessible only when the user is logged in  */}
          <Route path="/profile" element={<Profile />} />
        </Routes>
        {showSignUpModal && !isLoggedIn && (
          // sign up modal component -> render after 8 minutes of activity
          <div className="fixed inset-0 flex items-center justify-center fade-up">
            <div className="bg-secondary p-4 md:p-8 lg:p-9 rounded-xl shadow-xl text-center">
              <p className="text-xl text-textColor font-bold">
                Unlock all features by signing up!
              </p>
              <button
                className="bg-btn1 text-secondary px-6 py-3 rounded-md mt-8"
                onClick={() => setShowSignUpModal(false)}
              >
                <Link to="/signup">Sign Up</Link>
              </button>
            </div>
          </div>
        )}
      </Router>
    </>
  );
};
export default App;
