const Confirmation = () => {
  return (
    <div className="sm:w-[30vw] w-[80vw] sm:mt-40 mt-20 mx-auto bg-secondary sm:p-12 p-8 rounded-xl">
      <h1 className="text-center sm:mb-5 mb-2 sm:text-2xl text-xl font-bold text-textColor">
        Account Confirmation
      </h1>
      <p className="text-center sm:text-xl text-lg text-textColor">
        We need to confirm your email address. To complete the signup process,
        please follow the link in the email we just sent you.
      </p>
    </div>
  );
};

export default Confirmation;
