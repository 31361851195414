import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CheckPremiumStatus from "./CheckPremiumStatus";

const PremiumAd = ({ description, cta }) => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <CheckPremiumStatus>
      {(isPremiumUser) => (
        <div>
          {!isPremiumUser && isModalOpen && (
            <div className="sm:block flex justify-center">
              <div
                className={`fixed sm:right-4 sm:bottom-5 sm:top-auto top-[4.5rem] max-w-sm bg-[#fff] sm:p-2 px-2 py-1 shadow-lg rounded-xl z-50 bg-opacity-80`}
                data-aos="fade-left"
                data-aos-delay="3000"
              >
                <div className="flex">
                  <p className="sm:text-lg text-md font-semibold">
                    {description}
                  </p>
                  {/* Close button */}
                  <span
                    onClick={() => {
                      setIsModalOpen(false);
                    }}
                    className="cursor-pointer float-right text-3xl sm:ml-auto ml-2 mr-[0.12rem] mt-[-1rem]"
                  >
                    &times;
                  </span>
                </div>
                <div className="flex justify-center">
                  <Link to="/checkout">
                    <button className="bg-btn1 text-secondary sm:text-lg text-sm sm:px-3 px-2 sm:py-1 py-1 rounded-md mt-2 ">
                      {cta}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </CheckPremiumStatus>
  );
};
export default PremiumAd;
