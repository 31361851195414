import { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PremiumAd from "../components/PremiumAd";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import seodata from "../utils/seodata.json";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const About = () => {
  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Helmet>
        <title>{seodata.aboutpage.seotitle}</title>
        <meta
          name="description"
          content={seodata.aboutpage.seometadescription}
        />
        <meta
          name="keywords"
          content={seodata.aboutpage.seokeywords.join(", ")}
        />
        <h1>{seodata.aboutpage.seoh1}</h1>
        <h2>{seodata.aboutpage.seoh2.join(", ")}</h2>
        <body>{seodata.aboutpage.seobody}</body>
      </Helmet>
      <PremiumAd
        description={
          <>
            <span className="hidden sm:inline">
              💌 Know Us Better, Ad-Free! Premium offers a distraction-free
              experience on our key pages.
            </span>
            <span className="sm:hidden">💌 Know Us Better, Ad-Free!</span>
          </>
        }
        cta="Join Premium"
      />
      <Header />
      <div
        className="flex flex-col sm:flex-row items-center justify-between sm:space-x-20 sm:mt-12 mt-3 mb-10 sm:mb-20 px-20"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute sm:left-14 top-4 left-4 sm:top-2"
        >
          <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
        </button>
        <div className="sm:w-full w-[90vw] bg-secondary rounded-lg sm:px-5 px-1 lg:pt-11 pt-5 lg:pb-16 pb-8 sm:mt-auto mt-6">
          <div className="text-center">
            <h2 className="lg:text-2xl sm:text-xl text-lg font-bold text-textColor">
              About DrawInkPaper
            </h2>
          </div>
          <div className="lg:mt-12 mt-3 lg:px-9 px-3">
            <p className="lg:text-xl text-md mt-5 font-bold text-textColor">
              Drawinkpaper.com and its mobile applications are subsidiary
              products of Alai Technologies LLC.
            </p>
            <h3 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              Empowering Creativity Through Accessible Art Education
            </h3>
            <p className="mt-3 lg:text-lg text-sm text-textColor">
              At DrawInkPaper.com, our mission is to foster artistic expression
              and skill development by providing a user-friendly platform for
              learning and practicing drawing. We are dedicated to making the
              joy and benefits of drawing accessible to people of all ages, from
              beginners to more experienced artists. Through our intuitive
              learning tools and engaging coloring canvas, we aim to inspire
              creativity, enhance artistic abilities, and offer a space for fun
              and relaxation. We believe that everyone has the potential to
              create beautiful art, and we are committed to helping our users
              discover and nurture their artistic talents.
            </p>
            <p className="mt-5 lg:text-lg text-md text-textColor font-bold">
              Artist - Tiruchana Bhandari
            </p>
            <h3 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              Choose Your Creative Path with Our Subscription Plans
            </h3>
            <p className="mt-3 lg:text-lg text-sm text-textColor">
              Unlock your artistic potential with DrawInkPaper.com. Our
              subscription plans are tailored to fit your artistic needs,
              offering an ad-free, enriching art experience. Select the plan
              that resonates with your creative journey.
            </p>
            <h4 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              DrawInkPaper Premium (Yearly: $29)
            </h4>
            <ul className="mt-3 lg:text-lg text-sm">
              <li>1. Ad-Free Access on Website and App</li>
              <li>2. Regular New Drawing Tutorials</li>
              <li>
                <a
                  href="https://drawinkpaper.com/checkout"
                  target="_new"
                  className="text-primary"
                >
                  3. Subscribe Now
                </a>
              </li>
            </ul>
            <h4 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              DrawInkPaper App + eBook Package (Yearly: $49)
            </h4>
            <ul className="mt-3 lg:text-lg text-sm">
              <li>1. All Premium Features</li>
              <li>2. Two "How to Draw" eBooks</li>
              <li>3. Two Custom Drawing Requests</li>
              <li>
                <a
                  href="https://drawinkpaper.com/checkout"
                  target="_new"
                  className="text-primary"
                >
                  Subscribe Now
                </a>
              </li>
            </ul>
            <h4 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              Lifetime Access (One-Time: $99)
            </h4>
            <ul className="mt-3 lg:text-lg text-sm">
              <li>1. Lifetime Premium Access</li>
              <li>2. Ever-Growing Drawing Library</li>
              <li>3. One-Time Payment for Lifetime Benefits</li>
              <li>
                <a
                  href="https://drawinkpaper.com/checkout"
                  target="_new"
                  className="text-primary"
                >
                  Subscribe Now
                </a>
              </li>
            </ul>
            <h4 className="lg:text-xl text-md mt-5 font-bold text-textColor">
              Want to talk? Reach out to us at hello@drawinkpaper.com
            </h4>
            <br></br>
            <h4 className="lg:text-xl text-md font-bold text-textColor">
              Dive into a world of creativity and let your imagination soar with
              DrawInkPaper.com!
            </h4>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
