import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import premiumTag from "../assets/premium.png";

const Popular = ({ item, word, isPremium }) => {
  // Function to track button clicks with Google Analytics
  const trackCardClick = (category, word) => {
    ReactGA.event({
      category: "Card Click",
      action: `Clicked ${word} ${category} Card`,
    });
  };

  // Replace spaces with hyphens in the title for the URL
  const formattedTitle = item.title.replace(/\s+/g, "-");

  return (
    <Link to={`/${word}/${item.category}/${formattedTitle}`}>
      <div
        className="bg-secondary sm:py-5 py-2 rounded-xl cursor-pointer"
        onClick={() => trackCardClick(item.category, word)}
      >
        <div className="flex justify-center">
          <img
            src={item.image}
            alt={item.imageAltTag}
            className="sm:w-48 w-24"
          />
        </div>
        <div className="text-center sm:mt-3 mt-2 flex justify-center space-x-2">
          <p className="sm:text-xl text-textColor">{item.title}</p>
          {isPremium && (
            <img src={premiumTag} alt="premium" className="sm:w-7 w-4" />
          )}
        </div>
      </div>
    </Link>
  );
};

export default Popular;
