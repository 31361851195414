import { Client, Account, Databases, Storage, Avatars, Functions } from "appwrite";
const client = new Client();

const appwriteEndpoint = process.env.REACT_APP_APPWRITE_API_ENDPOINT;
const appwriteProjectId = process.env.REACT_APP_APPWRITE_PROJECT_ID;
const appwriteDatabaseId = process.env.REACT_APP_APPWRITE_DATABASE_ID;

client.setEndpoint(appwriteEndpoint).setProject(appwriteProjectId);

// Account
export const account = new Account(client);

// Database
export const databases = new Databases(client, appwriteDatabaseId);

// Storage
export const storage = new Storage(client);

// Avatars
export const avatars = new Avatars(client);

export const functions = new Functions(client);



