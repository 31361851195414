import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { signupUser, googleAuth } from "../utils/authUtils";
import googleIcon from "../assets/googleIcon.png";

const SignUp = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // navigate hook
  const navigate = useNavigate();

  // States for user credentials
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  // Function to handle signup
  const handleSignUp = async (e) => {
    e.preventDefault();
    signupUser(
      user.name,
      user.email,
      user.password,
      setLoading,
      setError,
      navigate
    );
  };

  // Function to handle Google authentication
  const handleGoogleAuth = (e) => {
    e.preventDefault();
    googleAuth();
  };

  return (
    <>
      <div className="sm:mt-9 mt-6 sm:mb-20 mb-10">
        <div className="mt-12 mb-9">
          <h1 className="text-center font-bold sm:text-4xl text-xl text-textColor">
            Hey, Young Artist!
          </h1>
          <p className="text-center text-textColor sm:mt-2 mt-1 sm:text-lg text-sm sm:px-0 px-5">
            Sign Up to Start your Artistic Journey with DrawInkPaper
          </p>
        </div>
        <div className="bg-secondary mx-auto rounded-2xl sm:px-9 sm:py-7 px-7 py-4 lg:w-[27vw] md:w-[40vw] w-[90vw]">
          <h1 className="sm:text-2xl text-xl font-bold text-textColor text-center mb-5">
            Sign Up
          </h1>
          <form onSubmit={handleSignUp}>
            <div className="sm:mb-4 mb-3">
              <label className="text-textColor sm:text-base text-sm">
                Name
              </label>
              <input
                required
                type="text"
                placeholder="Enter your Name"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md sm:mt-2 mt-1"
                onChange={(e) => {
                  setUser({
                    ...user,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="sm:mb-4 mb-2">
              <label className="text-textColor sm:text-base text-sm">
                Email Address
              </label>
              <input
                required
                type="text"
                placeholder="Enter your Email Address"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md sm:mt-2 mt-1"
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  });
                }}
              />
            </div>
            <div>
              <label className="text-textColor sm:text-base text-sm">
                Password
              </label>
              <input
                required
                type="password"
                placeholder="Enter your Password"
                className="w-full px-4 py-2 rounded-lg outline-none border-2 border-textColor text-md sm:mt-2 mt-1"
                onChange={(e) => {
                  setUser({
                    ...user,
                    password: e.target.value,
                  });
                }}
              />
            </div>
            <div className="sm:mt-5 mt-3">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={acceptTerms}
                  onChange={() => {
                    setAcceptTerms(!acceptTerms);
                  }}
                  className="mr-2 cursor-pointer"
                />
                <span className="text-textColor text-md">
                  I accept the{" "}
                  <Link to="/termsofuse">
                    {" "}
                    <span className="text-primary"> terms of use </span>{" "}
                  </Link>
                </span>
              </label>
            </div>
            <div className="sm:mt-7 mt-5">
              <button
                type="submit"
                className={`text-center w-full bg-btn1 text-secondary p-2 rounded-lg sm:text-lg text-md hover:bg-primary ${
                  loading || !acceptTerms ? "opacity-80" : ""
                }`}
                disabled={loading || !acceptTerms}
              >
                {loading ? "Loading..." : "Signup"}{" "}
              </button>
            </div>
            <div className="sm:mt-2 mt-5 sm:text-base text-sm">
              <p className="text-center text-textColor cursor-pointer">
                Already have an account?{" "}
                <Link to="/login">
                  <span className="font-bold hover:text-primary">Login</span>
                </Link>
              </p>
            </div>
            <div className="mt-1 text-center sm:text-lg text-sm text-textColor">
              <span>Or Signup with</span>
            </div>
            <div className="sm:mt-5 mt-3 flex justify-center space-x-5">
              <button className="cursor-pointer" onClick={handleGoogleAuth}>
                <img src={googleIcon} alt="googleicon" className="sm:w-9 w-7" />
              </button>
            </div>
            {error && (
              <div className="sm:mt-5 mt-4 sm:text-lg text-sm text-[#ff0101] font-bold text-center">
                <p>{error}</p>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
