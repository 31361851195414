import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { account } from "../appwrite/appwriteConfig";

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] =
    useState("Verifying email...");
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get("userId");
    const secret = urlParams.get("secret");

    const completeEmailVerification = async () => {
      try {
        await account.updateVerification(userId, secret);
        setVerificationStatus("Email verified! Redirecting to Login...");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } catch (error) {
        console.error(error);
        setVerificationStatus("Verification failed. Please try again.");
      }
    };

    completeEmailVerification();
  }, [navigate]);

  return (
    <div className="w-[30vw] mt-40 mx-auto bg-secondary py-12 px-9 rounded-xl">
      <h1 className="text-center mb-5 text-2xl font-bold text-textColor">
        Verification Status
      </h1>
      <p className="text-center text-xl text-textColor">{verificationStatus}</p>
    </div>
  );
};

export default VerifyEmail;
