import { Link } from "react-router-dom";
import { BsLinkedin } from "react-icons/bs";
import { AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";

const Footer = () => {
  return (
    <div className="flex flex-col sm:flex-row sm:p-7 justify-between items-center bg-primary p-6 text-secondary text-center w-full sm:text-lg">
      <div>
        <p>© 2023 drawinkpaper.com</p>
      </div>
      <div className="flex flex-col sm:flex-row sm:gap-5 mt-4 mb-9 sm:mt-0 sm:mb-0">
        <Link to="/allblogs">Blogs</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/privacypolicy">Privacy Policy</Link>
        <Link to="/refundpolicy">Refund Policy</Link>
        <Link to="/deleteyourdata">Your Data Requests & Rights</Link>
        <Link to="/termsofuse">Terms of Use</Link>
      </div>
      <div className="cursor-pointer">
        <div className="flex space-x-2 justify-center text-xl">
          <Link to="https://www.instagram.com/drawinknpaper/" target="_blank">
            <AiFillInstagram />
          </Link>
          <Link
            to="https://www.linkedin.com/company/draw-ink-paper/about/"
            target="_blank"
          >
            <BsLinkedin />
          </Link>
          <Link to="https://twitter.com/drawinknpaper" target="_blank">
            <AiOutlineTwitter />
          </Link>
        </div>
        <p className="mt-2">Alai Technologies Product</p>
      </div>
    </div>
  );
};

export default Footer;
