import { useState, useRef, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { databases } from "../appwrite/appwriteConfig";
import { ID } from "appwrite";

const FeedbackForm = ({ isOpen, closeModal }) => {
  // Initialize AOS
  useEffect(() => {
    AOS.init();
  }, []);

  // State variables
  const [isFeedbackSent, setIsFeedbackSent] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [userEmail, setUserEmail] = useState("");

  // Appwrite database and collection IDs
  const databaseId = process.env.REACT_APP_APPWRITE_DATABASE_ID;
  const userFeedbackCollectionId =
    process.env.REACT_APP_USER_FEEDBACK_COLLECTION_ID;

  // Function to submit feedback
  const submitFeedback = async (e) => {
    e.preventDefault();
    try {
      await databases.createDocument(
        databaseId,
        userFeedbackCollectionId,
        ID.unique(),
        {
          message: message,
          email: userEmail,
        }
      );
      setIsFeedbackSent(true);
    } catch (error) {
      setError(error.message);
      console.log(error);
    }
  };

  // Close modal when clicked outside
  const ref = useRef();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        closeModal();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, closeModal]);

  return (
    <>
      {isOpen ? (
        <>
          <div
            className="sm:flex sm:justify-end sm:top-[-100px] fixed inset-0 z-10 overflow-y-auto"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>
            <div className="flex items-center min-h-screen px-4 py-8">
              <div
                ref={ref}
                className="relative w-full max-w-lg mx-auto bg-secondary rounded-xl shadow-lg sm:p-9 p-5 bg-opacity-95"
              >
                {isFeedbackSent ? (
                  // Show success message if feedback is sent successfully
                  <>
                    <h1 className="text-center sm:text-xl text-sm font-bold text-textColor">
                      Thank you for submitting your feedback!
                    </h1>
                    <p className="text-center mt-3 font-bold text-textColor sm:text-lg text-sm">
                      Our team reads each feedback carefully and will reach out
                      to you with a 20% discount voucher on the premium
                      subscription. To know more about what your premium plan
                      will include, please refer{" "}
                      <a
                        href="https://drawinkpaper.com/checkout"
                        className="text-btn2"
                      >
                        here
                      </a>
                      .
                    </p>
                  </>
                ) : (
                  // Show feedback form if feedback is not sent
                  <div data-aos="fade-down" data-aos-delay="100">
                    <h1 className="text-center sm:text-xl font-bold text-textColor">
                      Share your Feedback with us!
                    </h1>
                    <form className="mt-7" onSubmit={submitFeedback}>
                      <div className="mb-2">
                        <label className="font-bold text-textColor sm:text-base text-sm">
                          Write your Feedback
                        </label>
                        <textarea
                          required
                          name="message"
                          cols="30"
                          rows="3"
                          placeholder="Enter your Feedback"
                          className="w-full mt-1 px-3 py-2 sm:text-base text-sm rounded-md border-[1px]"
                          onChange={(e) => setMessage(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="mb-7">
                        <label className="font-bold text-textColor sm:text-base text-sm">
                          Email Address
                        </label>
                        <input
                          required
                          name="user_email"
                          type="email"
                          placeholder="Enter your Email"
                          className="w-full mt-1 px-3 py-2 rounded-md border-[1px] sm:text-base text-sm"
                          onChange={(e) => setUserEmail(e.target.value)}
                        />
                      </div>
                      <div className="flex space-x-3">
                        <button
                          type="submit"
                          className="w-full bg-primary text-secondary sm:text-lg px-3 py-2 rounded-lg hover:opacity-80 text-sm"
                        >
                          Send Feedback
                        </button>
                        <button
                          type="button"
                          className="w-full bg-btn1 text-secondary sm:text-lg px-3 py-2 rounded-lg hover:opacity-80 text-sm"
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      </div>
                      {error && (
                        <p className="text-center font-bold text-[#ff0101] text-lg mt-7">
                          There was an error in sending your feedback. Please
                          try again later.
                        </p>
                      )}
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default FeedbackForm;
