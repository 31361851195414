import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/free-mode";
import { FreeMode, Autoplay, Navigation } from "swiper/modules";
import Popular from "./Popular";
import getSimilarDrawings from "../utils/similarDrawings";

const Similar = () => {
  // Get the information from current url path
  const pathName = window.location.pathname;
  const pathNameArray = pathName.split("/");
  const word = pathNameArray[1];
  const currentCategoryName = pathNameArray[2];
  const currentDrawingName = pathNameArray[3];

  // Get the similar drawings
  const similarDrawings = getSimilarDrawings(currentDrawingName);

  return (
    <>
      {similarDrawings.length > 0 ? (
        <div className="sm:px-12 pt-3 px-5">
          <div className="flex justify-between">
            <h1 className="lg:text-3xl sm:text-2xl text-lg font-bold text-textColor">
              Similar Drawings
            </h1>
            <div>
              <Link
                to={`/similar-drawings/${currentCategoryName}/${currentDrawingName}`}
              >
                <button className="bg-btn1 text-secondary lg:px-3 lg:py-2 px-1 py-1 sm:text-md text-sm rounded-lg hover:opacity-80">
                  View More
                </button>
              </Link>
            </div>
          </div>
          <div className="sm:mt-5 mt-3 mb-12">
            <Swiper
              breakpoints={{
                340: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                700: {
                  slidesPerView: 3,
                  spaceBetween: 15,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              freeMode={true}
              autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
              navigation
              loop={true}
              modules={[FreeMode, Autoplay, Navigation]}
            >
              {similarDrawings.map((item, index) => (
                <SwiperSlide key={index}>
                  <Popular item={item} word={word} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Similar;
