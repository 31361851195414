import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import Footer from "../components/Footer";
import backIcon from "../assets/backbutton.png";
import { goBack } from "../utils/backFunction";
import AOS from "aos";
import "aos/dist/aos.css";

const DeleteYourData = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // Animate on Scroll
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Header />
      <div
        className="flex justify-between lg:space-x-14 mt-12 lg:mb-20 mb-9 lg:px-20 px-5"
        data-aos="fade-left"
      >
        <button
          onClick={goBack}
          className="absolute sm:left-14 left-3 lg:top-auto sm:top-4 top-[-0.5rem]"
        >
          <img src={backIcon} alt="back" className="lg:w-10 sm:w-9 w-7" />
        </button>
        {/* MainContent */}
        <div className="lg:w-full w-[90vw] bg-secondary rounded-lg lg:px-5 px-2 lg:pt-11 pt-5 lg:pb-16 pb-8 mx-auto">
          <div className="text-center">
            <h2 className="sm:text-2xl text-lg sm:pt-0 pt-3 font-bold text-textColor">
              Delete Your Account and related Data
            </h2>
          </div>
          <div className="sm:mt-12 mt-5 sm:px-9 px-2">
            <p className="sm:text-lg text-sm text-textColor">
              Understanding your right to data privacy and control, we provide
              you with the option to delete your account and personal data from
              our system. This process complies with the General Data Protection
              Regulation (GDPR) and other data protection laws.
            </p>
            <h3 className="sm:text-xl text-lg font-bold mt-4">
              How to Request Data Deletion
            </h3>
            <p className="sm:text-lg text-sm text-textColor mt-2">
              You can delete your data by following these steps:
              <ol className="list-decimal ml-4">
                <li>
                  Find out your registered email address on drawinkpaper.com
                </li>
                <li>
                  Contact us at hello@drawinkpaper.com from your registered
                  email with subject line "Delete my Account and Data"
                </li>
                <li>
                  We will contact you within 7 days of reqest and give you
                  infomration about data stored along with acceptance of your
                  deletion request
                </li>
                <li>
                  Within 30 days of request, all your data and account will be
                  permanently deleted from DrawInkPaper servers.{" "}
                </li>
              </ol>
            </p>
            <p className="sm:text-lg text-sm text-textColor mt-4">
              After submitting your request, your data will be permanently
              removed from our active databases. Please note that some
              information might be retained in our backup systems for a certain
              period as per legal requirements.
            </p>
            <h3 className="sm:text-xl text-lg font-bold mt-4">Contact Us</h3>
            <p className="sm:text-lg text-sm text-textColor mt-2">
              If you have any questions about how to delete your data or our
              data handling practices, please reach out to us at
              hello@drawinkpaper.com
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DeleteYourData;
