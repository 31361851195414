const DrawCanvasSideBar = ({ description, isPremium }) => {
  return (
    <>
      <div className="bg-secondary h-full sm:p-9 px-9 pb-9 sm:mt-0 mt-[-1.5rem] lg:w-[20vw] md:w-[24vw] w-[100vw] rounded-xl text-center sm:bg-opacity-100 bg-opacity-50">
        <div>
          <h1 className="lg:text-xl text-lg text-textColor font-bold">
            INSTRUCTIONS
          </h1>
          <h1 className="lg:text-lg text-md font-bold mt-4 italic bg-[#FFFF00]">
            Click Next to follow the steps and complete the drawing.
          </h1>
          <p className="sm:mt-5 mt-2 lg:text-lg text-md text-textColor font-bold">
            {isPremium
              ? "Upgrade to Premium to View Next Step Instructions!"
              : description}
          </p>
        </div>
      </div>
    </>
  );
};

export default DrawCanvasSideBar;
