import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import blogs from "../utils/blogs.json";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const Blog = () => {
  const path = window.location.pathname;
  const blogTitle = decodeURIComponent(path.split("/")[2].replace(/-/g, " "));

  return (
    <>
      <Helmet>
        {blogs.blogs.map((blog) => {
          if (blog.title === blogTitle) {
            return [
              <title key="title">{blog.seo.seotitle}</title>,
              <meta
                key="description"
                name="description"
                content={blog.seo.seometadescription}
              />,
              <meta
                key="keywords"
                name="keywords"
                content={blog.seo.seokeywords.join(", ")}
              />,
              <h1 key="h1">{blog.seo.seoh1}</h1>,
              <h2 key="h2">{blog.seo.seoh2.join(", ")}</h2>,
              <body key="body">{blog.seo.seobody}</body>,
            ];
          }
          return null;
        })}
      </Helmet>
      <Header />
      <div className="min-h-screen sm:w-[85vw] w-[90vw] lg:my-16 my-8 mx-auto">
        <button onClick={goBack} className="absolute sm:left-10 top-24">
          <img src={backIcon} alt="back" className="sm:w-10 w-7" />
        </button>
        {/* eslint-disable-next-line */}
        {blogs.blogs.map((blog) => {
          if (blog.title === blogTitle) {
            return (
              <div
                key={blog.id}
                className="bg-secondary sm:p-12 p-5 rounded-xl sm:mt-2"
              >
                <h1 className="sm:text-3xl text-xl font-bold text-textColor text-center sm:mb-9 mb-5">
                  {blog.title}
                </h1>
                {blog.content.map((item) => (
                  <div key={item.subtitle} className="py-3">
                    <h2 className="sm:text-2xl text-xl font-bold text-btn1">
                      {item.subtitle}
                    </h2>
                    {item.subcontent.map((subItem) => (
                      <div key={subItem.text} className="sm:py-2 sm:mt-0 mt-2">
                        {subItem.titleLink ? (
                          <Link
                            to={subItem.titleLink}
                            className="hover:text-btn2"
                          >
                            <h4 className="sm:text-xl font-bold">
                              {subItem.bulletpoint}
                            </h4>
                          </Link>
                        ) : (
                          <h4 className="sm:text-xl font-bold">
                            {subItem.bulletpoint}
                          </h4>
                        )}
                        <div className="mt-2">
                          {subItem.link ? (
                            <a href={subItem.link}>
                              <p className="sm:text-lg text-btn2 hover:text-primary">
                                {subItem.text}
                              </p>
                            </a>
                          ) : (
                            <p className="sm:text-lg">{subItem.text}</p>
                          )}
                        </div>
                        <div className="my-2">
                          {subItem.drawingLink && (
                            <Link to={subItem.drawingLink.link}>
                              <p className="sm:text-xl font-bold text-btn2 hover:text-primary">
                                {subItem.drawingLink.drawing}
                              </p>
                            </Link>
                          )}
                        </div>
                        <div className="flex flex-wrap sm:space-x-5 sm:mb-0 mb-5">
                          {subItem.img && Array.isArray(subItem.img) ? (
                            subItem.img.map((img) => (
                              <img
                                key={img.image}
                                src={img.image}
                                alt="blog"
                                className="w-80 border-[0.5px] border-btn1 rounded-lg sm:my-3 mb-2"
                              />
                            ))
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            );
          }
        })}
      </div>
      <Footer />
    </>
  );
};

export default Blog;
