import React, { useState, useEffect } from "react";
import ReactGA from "react-ga4";
import Header from "../components/Header";
import DrawCanvasSideBar from "../components/DrawCanvasSideBar";
import Ads from "../components/Ads";
import Modal from "../components/Modal";
import Similar from "../components/Similar";
import KidsAlsoLike from "../components/KidsAlsoLike";
import PremiumAd from "../components/PremiumAd";
import UpgradeToPremiumModal from "../components/UpgradeToPremiumModal";
import FeedbackForm from "../components/FeedbackForm";
import Popular from "../components/Popular";
import DrawCanvasHelp from "../components/DrawCanvasHelp";
import CheckPremiumStatus from "../components/CheckPremiumStatus";
import { useParams, Link } from "react-router-dom";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import AOS from "aos";
import "aos/dist/aos.css";
import data from "../utils/data.json";
import { goBack } from "../utils/backFunction";
import adsConfig from "../utils/adsConfig";
import filteredDrawings from "../utils/searchData";
import leftArrow from "../assets/previous.png";
import rightArrow from "../assets/next.png";
import tick from "../assets/correct.png";
import preview from "../assets/view.png";
import backIcon from "../assets/backbutton.png";
import { BsInfoCircleFill } from "react-icons/bs";

const DrawCanvas = () => {
  // check if the user is logged in or not
  const isLoggedIn = localStorage.getItem("cookieFallback");

  // Get the category and title from the URL
  const { category, title } = useParams();

  // remove the hyphens from the title
  const formattedTitle = title.replace(/-/g, " ");

  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // Filter and extract items from the specified category
  const itemsArray = data.drawings.find(
    (item) => item.category === category
  ).items;

  // Get Instructions Steps and check if the drawing is premium or not
  let steps = [];
  let isPremium = false;
  itemsArray.forEach((item) => {
    if (item.title === formattedTitle) {
      steps = item.steps;
      isPremium = item.isPremium;
    }
  });

  // Get SEO Data
  let seoData = {};
  itemsArray.forEach((item) => {
    if (item.title === formattedTitle) {
      seoData = item.seo;
    }
  });

  // Get Step Images
  let images = [];
  steps.forEach((stepItem) => {
    images.push(stepItem.imageUrl);
  });

  // Get Step Description
  let description = [];
  steps.forEach((stepItem) => {
    description.push(stepItem.description);
  });

  // Initialize state to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Initialize state to keep track of the current image index
  const [currentDescriptionIndex, setCurrentDescriptionIndex] = useState(0);

  const [animationClass, setAnimationClass] = useState("");

  const backButton = () => {
    if (currentImageIndex > 0) {
      setAnimationClass(""); // Reset the animation class
      setTimeout(() => {
        setAnimationClass("slide-right"); // Set the animation class after a delay
        setCurrentImageIndex(currentImageIndex - 1);
      }, 0);
    }
  };
  
  const nextButton = () => {
    if (currentImageIndex < images.length - 1) {
      setAnimationClass(""); // Reset the animation class
      setTimeout(() => {
        setAnimationClass("slide-left"); // Set the animation class after a delay
        setCurrentImageIndex(currentImageIndex + 1);
      }, 0);
    }
  };

  // Determine whether to disable the "Back" and "Next" buttons
  const isBackDisabled = currentImageIndex === 0;
  const isNextDisabled = currentImageIndex === images.length - 1;

  // check if it is the last step or not
  const isLastStep = currentImageIndex === images.length - 2;

  // State for modals
  const [showModal, setShowModal] = useState(false);
  const [isPreviewVisible, setPreviewVisibility] = useState(false);
  const [isLastStepModalVisible, setLastStepModalVisibility] = useState(true);
  const [isFeedbackFormVisible, setFeedbackFormVisibility] = useState(false);
  const [isHelpVisible, setHelpVisibility] = useState(false);

  // render draw canvas help component
  useEffect(() => {
    setHelpVisibility(true);
    // eslint-disable-next-line
  }, [window.location.pathname]);

  // Search Query
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filteredDrawings(searchQuery);

  // reset image indices when navigating to other drawings
  useEffect(() => {
    setCurrentImageIndex(0);
    setCurrentDescriptionIndex(0);
  }, [formattedTitle]);

  // empty the search query when navigating to other drawings
  useEffect(() => {
    setSearchQuery("");
  }, [formattedTitle]);

  return (
    <CheckPremiumStatus>
      {(isPremiumUser) => {
        return (
          <>
            <Helmet>
              <title>{seoData.seotitle}</title>
              <meta name="description" content={seoData.seometadescription} />
              <meta name="keywords" content={seoData.seokeywords.join(", ")} />
              <h1>{seoData.seoh1}</h1>
              <h2>{seoData.seoh2.join(", ")}</h2>
              <body>{seoData.seobody}</body>
              {!isPremiumUser && (
                // Scripts for non-premium users
                <script
                  async
                  src={adsConfig.googleAdSense.src}
                  crossorigin={adsConfig.googleAdSense.crossorigin}
                ></script>
              )}
            </Helmet>
            <PremiumAd
              description={
                <>
                  <span className="hidden sm:inline">
                    ✏️ Learn Without Ads! Premium unlocks all drawing tutorials,
                    coloring pages, and choice ebooks
                  </span>
                  <span className="sm:hidden">
                    ✏️ Learn Without Ads! Enjoy drawing
                  </span>
                </>
              }
              cta="Upgrade for Learning!"
            />
            {isPreviewVisible && (
              <div className="fixed top-0 left-0 w-full h-full backdrop-blur-lg" />
            )}
            {isLastStep && isLastStepModalVisible && (
              <UpgradeToPremiumModal
                onClose={() => setLastStepModalVisibility(false)}
                onFeedbackClick={() => setFeedbackFormVisibility(true)}
              />
            )}
            <FeedbackForm
              isOpen={isFeedbackFormVisible}
              closeModal={() => setFeedbackFormVisibility(false)}
            />
            <DrawCanvasHelp
              isOpen={isHelpVisible}
              onClose={() => setHelpVisibility(false)}
            />
            <Header
              category={category}
              setSearchQuery={setSearchQuery}
              searchQuery={searchQuery}
              showSearchBar={true}
            />
            {searchQuery && (
              <div className="mx-12 mb-12 mt-12">
                <h1 className="lg:text-2xl sm:text-xl font-bold text-textColor">
                  Search Results
                </h1>
                {filteredData.length === 0 ? (
                  <>
                    <div className="flex justify-center my-14">
                      <p className="text-xl text-textColor">
                        No results found for "{searchQuery}"
                      </p>
                    </div>
                  </>
                ) : (
                  <div className="mt-9 grid sm:grid-cols-4 grid-cols-2 sm:gap-7 gap-3">
                    {filteredData.map((drawing) => (
                      <Popular key={drawing.title} item={drawing} word="draw" />
                    ))}
                  </div>
                )}
              </div>
            )}
            <div className="flex justify-between px-5 pt-7 bg-secondary sm:bg-opacity-0 bg-opacity-50 sm:hidden">
              <button onClick={goBack}>
                <img src={backIcon} alt="back" className="w-7" />
              </button>
              <button onClick={() => setHelpVisibility(true)}>
                <BsInfoCircleFill className="text-3xl text-primary" />
              </button>
            </div>
            <div className="sm:flex sm:justify-around bg-secondary sm:bg-opacity-0 bg-opacity-50 pt-[1px] lg:mt-4 sm:mb-10 px-5 sm:px-0">
              <button onClick={goBack} className="lg:mt-4 mt-6 sm:block hidden">
                <img src={backIcon} alt="back" className="lg:w-9 w-7" />
              </button>
              <h1 className="text-center lg:text-3xl sm:text-lg text-md sm:mt-6 mt-5 font-bold text-textColor">
                {seoData.seotitle}
              </h1>
              <button
                className="mt-7 sm:block hidden"
                onClick={() => setHelpVisibility(true)}
              >
                <BsInfoCircleFill className="lg:text-4xl text-2xl text-primary" />
              </button>
            </div>
            <div
              className="flex sm:flex-row flex-col gap-6 sm:px-9 pb-8 lg:mt-4 mt-[-1.5rem] sm:mb-5 sm:items-stretch items-center"
              data-aos="fade-up"
            >
              <div>
                <Ads
                  image={images[images.length - 1]}
                  title={title}
                  isPreviewVisible={isPreviewVisible}
                  setPreviewVisibility={setPreviewVisibility}
                />
              </div>
              <div className="sm:w-[80vw] w-[100vw] bg-secondary rounded-xl sm:bg-opacity-100 bg-opacity-50 sm:py-0 pt-2">
                <div className="mb-2 mt-3">
                  {isPremium && !isPremiumUser && currentImageIndex >= 1 ? (
                    <div className="sm:my-28 mb-9 text-center">
                      <h1 className="lg:text-xl font-bold text-textColor sm:w-[35vw] w-[80vw] text-lg mx-auto">
                        This is one of our Premium drawings, and we pour some
                        extra love into them! Please upgrade to Premium to get
                        access to this and 100 other Premium drawings and free
                        ebooks.
                      </h1>
                      <div className="flex justify-center sm:mt-7 mt-2">
                        <Link to="/checkout">
                          <button className="bg-btn1 text-secondary lg:px-5 lg:py-3 lg:text-xl sm:rounded-xl font-bold hover:opacity-80 px-3 py-1 rounded-lg">
                            Upgrade Now!
                          </button>
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="sm:flex justify-center hidden overflow-hidden">
                        <img
                          src={images[currentImageIndex]}
                          alt={steps[currentImageIndex].imageUrlAltTag}
                          className={`sm:w-[25vw] w-[80vw] sm:h-auto h-[100vw] rounded-xl smooth-slide ${animationClass}`}
                        />
                      </div>
                      {/* // For Mobile */}
                      <div className="flex items-center mx-2 gap-2 sm:hidden">
                        <div>
                          <img
                            src={leftArrow}
                            alt="Go to previous"
                            className="sm:hidden w-8 h-8 cursor-pointer"
                            onClick={backButton}
                          />
                          <label className="text-sm font-bold">Back</label>
                        </div>
                        <div className="flex justify-center pverflow-hidden z-[-10]">
                          <img
                            src={images[currentImageIndex]}
                            alt={steps[currentImageIndex].imageUrlAltTag}
                            className={`sm:w-[25vw] w-[80vw] sm:h-auto h-[100vw] rounded-xl smooth-slide ${animationClass}`}
                          />
                        </div>
                        <div>
                          <img
                            src={rightArrow}
                            alt="Go to next"
                            className="sm:hidden w-8 h-8 cursor-pointer"
                            onClick={nextButton}
                          />
                          <label className="text-sm font-bold">Next</label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="sm:mb-7 sm:mt-0 my-5">
                  <p className="text-center lg:text-2xl sm:text-xl text-lg text-textColor">
                    {currentImageIndex + 1} of {images.length}
                  </p>
                </div>
                {isLoggedIn && (
                  <>
                    <div className="sm:flex hidden justify-center sm:items-stretch items-center space-x-5 mt-5 mb-9">
                      <button
                        className={`bg-btn1 text-secondary sm:block hidden px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn3 ${
                          isBackDisabled ? "opacity-20" : ""
                        }`}
                        onClick={backButton}
                        disabled={isBackDisabled}
                      >
                        Back
                      </button>
                      <button
                        className={`bg-primary text-secondary sm:block hidden px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn2 ${
                          isNextDisabled ||
                          (isPremium &&
                            !isPremiumUser &&
                            currentImageIndex >= 1)
                            ? "opacity-20"
                            : ""
                        }`}
                        onClick={nextButton}
                        disabled={
                          isNextDisabled ||
                          (isPremium &&
                            !isPremiumUser &&
                            currentImageIndex >= 1)
                        }
                      >
                        Next
                      </button>
                      <button
                        className={`bg-textColor text-secondary sm:block hidden px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn2 ${
                          isPremium && !isPremiumUser ? "opacity-20" : ""
                        }`}
                        onClick={() => setShowModal(true)}
                        disabled={!isPremiumUser && isPremium}
                      >
                        Save Drawing
                      </button>
                      <Modal
                        showModal={showModal}
                        category={category}
                        title={title}
                        onClose={() => {
                          setShowModal(false);
                        }}
                      />
                    </div>
                    {/* For Mobile */}
                    <div className="sm:hidden flex justify-center items-center space-x-10 mt-3">
                      <div className="flex flex-col items-center">
                        <img
                          src={preview}
                          alt="Preview"
                          className="sm:hidden w-10 h-10 cursor-pointer"
                          onClick={() => setPreviewVisibility(true)}
                        />
                        <label className="text-sm font-bold">Preview</label>
                      </div>

                      {!(isPremium && !isPremiumUser && isLoggedIn) && (
                        <div className="flex flex-col items-center">
                          <img
                            src={tick}
                            alt="Mark as done"
                            className="w-8 h-8 sm:hidden cursor-pointer"
                            onClick={() => setShowModal(true)}
                          />
                          <label className="text-sm font-bold">Upload</label>
                        </div>
                      )}
                      <Modal
                        showModal={showModal}
                        category={category}
                        title={title}
                        onClose={() => {
                          setShowModal(false);
                        }}
                      />
                    </div>
                  </>
                )}
                {!isLoggedIn && (
                  <>
                    <div className="sm:flex hidden justify-center space-x-5 mt-5 mb-9">
                      <button
                        className={`bg-btn1 text-secondary sm:block hidden px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn3 ${
                          isBackDisabled ? "opacity-20" : ""
                        }`}
                        onClick={backButton}
                        disabled={isBackDisabled}
                      >
                        Back
                      </button>
                      <button
                        className={`bg-primary text-secondary sm:block hidden px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn2 ${
                          isNextDisabled ||
                          (isPremium &&
                            !isPremiumUser &&
                            currentImageIndex >= 1)
                            ? "opacity-20"
                            : ""
                        }`}
                        onClick={nextButton}
                        disabled={
                          isNextDisabled ||
                          (isPremium &&
                            !isPremiumUser &&
                            currentImageIndex >= 1)
                        }
                      >
                        Next
                      </button>
                      <button
                        className="bg-textColor text-secondary px-3 py-2 rounded-xl lg:text-xl sm:text-lg text-md sm:w-[10vw] w-[20vw] hover:bg-btn3"
                        onClick={() => setShowModal(true)}
                      >
                        Save Drawing
                      </button>
                      <Modal
                        showModal={showModal}
                        category={category}
                        title={title}
                        onClose={() => setShowModal(false)}
                      />
                    </div>
                    {/* For Mobile */}
                    <div className="sm:hidden flex justify-center items-center space-x-10 mt-3">
                      <div className="flex flex-col items-center">
                        <img
                          src={preview}
                          alt=""
                          className="sm:hidden w-10 h-10 cursor-pointer"
                          onClick={() => setPreviewVisibility(true)}
                        />
                        <label className="text-sm">Preview</label>
                      </div>
                      <div className="flex flex-col items-center">
                        <img
                          src={tick}
                          alt=""
                          className="w-10 h-10 sm:hidden cursor-pointer"
                          onClick={() => setShowModal(true)}
                        />
                        <label className="text-sm font-bold">Upload</label>
                      </div>
                      <Modal
                        showModal={showModal}
                        category={category}
                        title={title}
                        onClose={() => setShowModal(false)}
                      />
                    </div>
                  </>
                )}
                <div className="my-7 px-9 flex justify-center">
                  <Link to={`/color/${category}/${title}`}>
                    <p className="sm:block hidden lg:text-xl text-lg font-bold text-textColor hover:text-btn1">
                      Finished your drawing?{" "}
                      <button className="bg-btn2 text-secondary sm:px-3 px-2 py-1 rounded-lg hover:opacity-80 text-md">
                        Click here to add some color now
                      </button>
                    </p>
                  </Link>
                </div>
              </div>
              <div>
                <DrawCanvasSideBar
                  description={description[currentDescriptionIndex]}
                  isPremium={isPremium && !isPremiumUser}
                />
                <div className="flex justify-center sm:hidden">
                  <Link to={`/color/${category}/${title}`}>
                    <button className="bg-btn2 text-secondary mt-3 px-2 py-1 rounded-lg hover:opacity-80 text-md">
                      Click here to add some color now
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <Similar />
            <KidsAlsoLike />
            <Footer />
          </>
        );
      }}
    </CheckPremiumStatus>
  );
};

export default DrawCanvas;
