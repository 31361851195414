import data from "./data.json";

const getSimilarDrawings = (currentDrawingName) => {
  const similarDrawings = new Set();
  // Find the current drawing based on the category in the path
  const currentCategoryItems = data.drawings.find((drawing) =>
    drawing.items.some((item) => item.title === currentDrawingName)
  );

  // Check if the currentCategoryItems is defined
  if (currentCategoryItems) {
    // Find the SEO keywords for the current drawing
    const currentDrawingSEOKeywords =
      currentCategoryItems.items.find((item) => item.title === currentDrawingName)
        ?.seo?.seowords;
    if (currentDrawingSEOKeywords) {
      // Find the similar drawings based on the SEO keywords
      data.drawings.forEach((drawing) =>
        drawing.items.forEach((item) => {
          if (item.title === currentDrawingName) {
            return;
          }
          item.seo.seowords.forEach((word) => {
            if (currentDrawingSEOKeywords.includes(word)) {
              similarDrawings.add(item);
            }
          });
        })
      );

      return Array.from(similarDrawings);
    }
  }

  // Return an empty array if the data is not found
  return [];
};

export default getSimilarDrawings;
