import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import SubHeader from "../components/SubHeader";
import Popular from "../components/Popular";
import ReactGA from "react-ga4";
import Footer from "../components/Footer";
import premiumDrawings from "../utils/premiumDrawings";
import filterDrawings from "../utils/searchData";

const PremiumDrawings = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // states for filtering the data
  const [searchQuery, setSearchQuery] = useState("");
  const filteredData = filterDrawings(searchQuery);

  return (
    <>
      <Header
        setSearchQuery={setSearchQuery}
        showSearchBar={true}
        searchQuery={searchQuery}
        category=""
      />
      <div className="lg:mt-12 mt-9 sm:px-20 px-5 h-full">
        <SubHeader
          category="Premium Drawings"
          categoryDescription="Pick Your Favorite and Start Drawing"
          isCategoryBtnVisible={false}
          setSearchQuery={setSearchQuery}
          showSearchBar={true}
        />
        {searchQuery && (
          <div className="sm:mb-16">
            <h1 className="sm:text-2xl text-xl lg:mt-9 mt-7 font-bold text-textColor">
              Search Results
            </h1>
            {filteredData.length === 0 ? (
              <>
                <div className="flex justify-center mt-3">
                  <p className="sm:text-xl text-textColor">
                    No results found for "{searchQuery}"
                  </p>
                </div>
              </>
            ) : (
              <div className="lg:mt-7 mt-3 grid sm:grid-cols-4 grid-cols-2 lg:gap-7 gap-3">
                {filteredData.map((drawing) => (
                  <Popular key={drawing.title} item={drawing} word="draw" />
                ))}
              </div>
            )}
          </div>
        )}
        <div className="lg:mt-7 mt-5 lg:mb-28 mb-14 grid sm:grid-cols-4 grid-cols-2 lg:gap-7 gap-3">
          {premiumDrawings.map((item) => (
            <Popular
              key={item.title}
              isPremium={item.isPremium}
              item={item}
              word="draw"
            />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PremiumDrawings;
