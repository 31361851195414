import { useState, useEffect, useRef } from "react";
import CheckPremiumStatus from "./CheckPremiumStatus";
import AOS from "aos";
import "aos/dist/aos.css";

const UpgradeToPremiumModal = ({ onClose, onFeedbackClick }) => {
  // State for managing the modal visibility
  const [isModalVisible, setModalVisibility] = useState(true);

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
  }, []);

  // close the modal on click outside
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);

  return (
    <CheckPremiumStatus>
      {(isPremiumUser) => (
        <div>
          {!isPremiumUser && isModalVisible && (
            <>
              <div
                className="flex justify-center sm:justify-end top-0 sm:top-20 sm:right-4 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                data-aos="fade-down"
                data-aos-delay="100"
              >
                <div className="mt-5">
                  <div
                    ref={ref}
                    className="rounded-xl shadow-xl relative flex flex-col sm:w-[30vw] w-[80vw] p-3 bg-secondary bg-opacity-95"
                  >
                    <h1 className="font-bold text-textColor text-center sm:text-left sm:text-base text-sm">
                      Because you've come this far, first of all Thank you! and
                      second, please leave us your valuable feedback of what you
                      think about our work and how can we help you better and
                      get flat 20% off on the premium subscription. Thanks for
                      your showing your support in advance!
                    </h1>
                    <span className="sm:text-right sm:mt-0 mt-2 font-bold text-textColor text-center sm:text-base text-sm">
                      {" "}
                      - Team DrawInkPaper
                    </span>
                    <div className="flex justify-center space-x-3 mt-3">
                      <button
                        className="bg-btn1 text-secondary sm:text-lg text-sm sm:px-3 px-2 py-1 rounded-lg hover:opacity-80"
                        onClick={() => {
                          onFeedbackClick();
                          onClose();
                        }}
                      >
                        Give Feedback
                      </button>
                      <button
                        className="bg-textColor text-secondary sm:text-lg text-sm sm:px-3 px-2 py-1 rounded-lg hover:opacity-80"
                        onClick={() => {
                          setModalVisibility(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          )}
        </div>
      )}
    </CheckPremiumStatus>
  );
};

export default UpgradeToPremiumModal;
