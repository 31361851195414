import { useEffect, useRef } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsInfoCircleFill } from "react-icons/bs";

const DrawCanvasHelp = ({ isOpen, onClose }) => {
  // Close modal when clicked outside
  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClose]);
  useEffect(() => {
    AOS.init();
  }, []);

  // Check if device is mobile
  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {isOpen && (
        <>
          <div
            className="flex justify-center sm:justify-end top-0 sm:top-20 sm:right-4 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            <div className="mt-5">
              <div className="rounded-xl shadow-xl relative flex flex-col sm:w-[30vw] w-[80vw] p-3 bg-secondary bg-opacity-95">
                <div
                  ref={ref}
                  className="flex space-x-2 sm:space-x-3 justify-between cursor-pointer"
                >
                  <p className="text-xl sm:text-3xl text-primary">
                    <BsInfoCircleFill />
                  </p>
                  <p className="sm:text-xl text-sm" onClick={onClose}>
                    ❌
                  </p>
                </div>
                <div className="mt-2 sm:mt-5">
                  <h1 className="font-bold text-textColor text-center text-sm sm:text-xl">
                    Grab some paper and crayon of your choice and let us start
                    drawing.
                  </h1>
                  <ul className="mt-3 sm:my-5 px-1 sm:px-3 text-sm sm:text-lg">
                    <li className="mb-2">
                      {isMobile ? (
                        <>
                          1. Click the arrow on the right to move to the next
                          step
                        </>
                      ) : (
                        <>
                          1. Click on the Next button to move to the next step.
                        </>
                      )}
                    </li>
                    <li className="mb-2">
                      {isMobile ? (
                        <>
                          2. Click the arrow on the left to move to the previous
                          step
                        </>
                      ) : (
                        <>
                          2. Click on the Back button to move to the previous
                          step.
                        </>
                      )}
                    </li>
                    <li className="mb-2">
                      {isMobile ? (
                        <>
                          3. To preview the final drawing click the preview
                          button below the drawing on the left.
                        </>
                      ) : (
                        <>3. The final drawing preview is shown on the left.</>
                      )}
                    </li>
                    <li className="mb-2">
                      {isMobile ? (
                        <>
                          4. Save and upload your handmade drawing by clicking
                          the green check mark to earn rewards.{" "}
                          <b>Happy Painting!! 🎨🖌️</b>{" "}
                        </>
                      ) : (
                        <>
                          4. Save and upload your handmade drawing by clicking
                          the Save Drawing button to earn rewards.{" "}
                          <b>Happy Painting!! 🎨🖌️</b>{" "}
                        </>
                      )}
                    </li>
                  </ul>
                  <div className="mt-3 flex justify-center">
                    <button
                      type="button"
                      className="bg-primary text-secondary px-2 py-1 text-sm sm:px-3 sm:py-2 sm:text-xl rounded-xl"
                      onClick={onClose}
                    >
                      OKAY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default DrawCanvasHelp;
