import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import ProfileCard from "../components/ProfileCard";
import Progress from "../components/Progress";
import Settings from "../components/Settings";
import Footer from "../components/Footer";
import Login from "./Login";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import AOS from "aos";
import "aos/dist/aos.css";
import { goBack } from "../utils/backFunction";
import backIcon from "../assets/backbutton.png";

const Profile = () => {
  // page view analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  // state to store which component to show
  const [activeComponent, setActiveComponent] = useState();

  // check if the user is coming from upload progress page
  const location = useLocation();
  useEffect(() => {
    if (location.state) {
      setActiveComponent(location.state.from);
    }
  }, [location]);

  //Animate on Scroll
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  // check if user is logged in or not
  const isLoggedIn = localStorage.getItem("cookieFallback");

  return (
    <>
      {isLoggedIn ? (
        <div data-aos="fade-up">
          <Header showSearchBar={false} />
          <div>
            <button
              onClick={goBack}
              className="absolute sm:left-14 sm:top-36 left-5 top-24"
            >
              <img src={backIcon} alt="back" className="sm:w-10 w-7" />
            </button>
          </div>
          <div className="flex sm:flex-row flex-col sm:space-x-10 pt-5 sm:px-12 sm:mt-5 mt-16 min-h-screen">
            <ProfileCard
              onProgressClick={() => setActiveComponent("progress")}
              onSettingsClick={() => setActiveComponent("settings")}
            />
            {activeComponent === "progress" && <Progress />}
            {activeComponent === "settings" && <Settings />}
          </div>
          <Footer />
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Profile;
