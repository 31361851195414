// adsConfig.js
// adsConfig.js
const adsConfig = {
  googleAdSense: {
    async: true,
    src: "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7231898813780728",
    crossorigin: "anonymous"
  },
  monetag: ""
};

export default adsConfig;
  