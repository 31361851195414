// Get the color of the pixel at (x, y)
export const getColorAtPixel = (imageData, x, y) => {
  const { width, data } = imageData;
  const index = (y * width + x) * 4;
  return [data[index], data[index + 1], data[index + 2], data[index + 3]];
};

// Set the color of the pixel at (x, y)
export const setColorAtPixel = (imageData, color, x, y) => {
  const { width, data } = imageData;
  const index = (y * width + x) * 4;
  [data[index], data[index + 1], data[index + 2], data[index + 3]] = color;
};

// Check if the color at (x, y) matches targetColor
export const colorsMatch = (a, b, tolerance = 64) => {
  return (
    Math.abs(a[0] - b[0]) <= tolerance &&
    Math.abs(a[1] - b[1]) <= tolerance &&
    Math.abs(a[2] - b[2]) <= tolerance
  );
};

// Fill the color at (startX, startY) with fillColor
export const fill = (ctx, canvas, startX, startY, targetColor, fillColor) => {
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const { width, height } = imageData;
  const processed = new Uint8Array(width * height);
  const queue = [[startX, startY]];

  while (queue.length) {
    const [x, y] = queue.shift();
    const index = y * width + x;

    if (processed[index]) continue;
    processed[index] = 1;

    const currentColor = getColorAtPixel(imageData, x, y);

    if (colorsMatch(currentColor, targetColor)) {
      setColorAtPixel(imageData, fillColor, x, y);
      queue.push([x + 1, y], [x - 1, y], [x, y + 1], [x, y - 1]);
    }
  }
  ctx.putImageData(imageData, 0, 0);
};
